define("iris/components/beta/cmdb/modals/business-services/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    data: null,
    // @, { entities: object }
    close: null,
    // @, function
    entities: Ember.computed.alias('data.entities'),
    selectedBusinessServiceIds: Ember.computed('entities', function () {
      let selectedBusinessServiceIds = [];
      this.entities.forEach(entity => {
        selectedBusinessServiceIds = selectedBusinessServiceIds.concat(entity.get('attachedBusinessServices').toArray().map(bs => bs.id));
      });
      return {
        inq: selectedBusinessServiceIds
      };
    }),
    // Properties that'll end up on the entities
    partner: null,
    businessServices: null,
    businessServicesToRemove: null,
    actions: {
      async addBusinessServices() {
        if (this.businessServices) {
          await Promise.all(this.entities.map(entity => {
            let existingBusinessServices = entity.get('attachedBusinessServices').toArray();
            let newBusinessServices = existingBusinessServices.concat(this.businessServices);
            entity.set('attachedBusinessServices', newBusinessServices);
            return entity.save(true);
          }));
          this.close({
            shouldRefreshRecords: true
          });
        }
      },

      async removeBusinessServices() {
        if (this.businessServicesToRemove) {
          const businessServicesToRemoveIds = this.businessServicesToRemove.toArray().map(bs => bs.id);
          await Promise.all(this.entities.map(entity => {
            let existingBusinessServices = entity.get('attachedBusinessServices').toArray();
            let newBusinessServices = existingBusinessServices.filter(bs => !businessServicesToRemoveIds.includes(bs.id));
            entity.set('attachedBusinessServices', newBusinessServices);
            return entity.save(true);
          }));
          this.close({
            shouldRefreshRecords: true
          });
        }
      }

    }
  });

  _exports.default = _default;
});