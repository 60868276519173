define("iris/components/change-requests/edit/tasks/affected-entities/select/component", ["exports", "iris/lib/filters", "iris/lib/filters/column", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/grid", "iris/utils/properties"], function (_exports, _filters, _column, _storage, _types, _grid, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    storage: (0, _storage.useStorage)('memory'),
    model: null,

    // @
    init() {
      this._super(...arguments);

      let partnerColumn = (0, _filters.makeColumn)({
        name: 'partnerId',
        type: _types.AttributeType.BelongsTo
      });
      let partnerFilter = (0, _filters.makeFilterFromColumn)(partnerColumn, {
        filterType: _types.FilterType.Equal,
        value: {
          id: this.partner.get('id'),
          name: this.partner.get('name')
        }
      });
      this.storage.update('filters', (0, _filters.serializeToQueryParam)([partnerFilter]));
      this.storage.update('limit', 10);

      if (this.bulkActions) {
        this.set('bulkActions.selection', this.model.entities.toArray());
        this.set('bulkActions.showWithoutSelection', true);
      }
    },

    columns: (0, _column.columns)(function () {
      return [{
        name: 'name',
        displayName: this.intl.t('models.changeRequest.name'),
        fixed: true
      }, {
        name: 'partner',
        displayName: this.intl.t('models.changeRequest.partner'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'partner',
        displayName: this.intl.t('models.changeRequest.partnerName'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'partnerId',
        displayName: this.intl.t('models.changeRequest.partner'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'categoryId',
        displayName: this.intl.t('models.changeRequest.category'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter,
        optionsResourceName: 'entityTypeCategory'
      }, {
        name: 'entityTypeCategory',
        displayName: this.intl.t('models.changeRequest.category'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'entityType',
        displayName: this.intl.t('models.changeRequest.entityTypeCategory'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'entityType',
        displayName: this.intl.t('models.changeRequest.entityTypeCategoryName'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'entityTypeId',
        displayName: this.intl.t('models.changeRequest.entityTypeCategory'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter,
        belongsTo: [{
          categoryId: 'entityTypeCategoryId'
        }],
        createOptions: async (records, nameKey, defaultMapper) => {
          let etcs = await this.api.findAll('entityTypeCategory');
          let groups = etcs.map(etc => {
            return {
              groupName: etc.name,
              options: defaultMapper(records.filter(et => et.belongsTo('entityTypeCategory').id() === etc.id))
            };
          });
          let ungrouped = {
            groupName: this.intl.t('forms.other'),
            options: defaultMapper(records.filter(et => !et.belongsTo('entityTypeCategory').id()))
          };
          return [...groups, ungrouped];
        }
      }];
    }),
    bulkActions: (0, _grid.bulkActions)(function () {
      const {
        intl
      } = this;
      return [{
        name: intl.t('models.changeRequest.affectedEntities'),
        icon: 'save',
        action: async selection => await this.onUpdateEntities(selection)
      }];
    }),
    defaultFilters: Ember.computed(function () {
      return filters => {
        let hasFilterForEntityType = filters.find(filter => ['entityTypeId', 'entityType'].includes(filter.attributeName));

        if (hasFilterForEntityType) {
          // When `entityType*` already exists, we don't want to filter for 'All'
          return [];
        }

        let column = (0, _filters.makeColumn)({
          name: 'entityTypeId'
        });
        let allEntityAttributesFilter = (0, _filters.makeFilterFromColumn)(column, {
          attributeName: 'entityTypeId',
          filterType: _types.FilterType.Equal,
          value: 'All'
        });
        return [allEntityAttributesFilter];
      };
    }),
    fetchParams: Ember.computed('columns', 'defaultFilters', 'storage.params', function () {
      let queryObject = (0, _filters.makeQueryObject)(this.columns, this.storage.params, {
        defaultFilters: this.defaultFilters
      });
      queryObject.filter.where.and.push({
        or: [{
          statusId: {
            neq: 'SYS_02'
          }
        }, {
          statusId: {
            eq: null
          }
        }]
      });
      return queryObject;
    }),
    entityViewResult: (0, _properties.computedTask)('fetchParams', 'model.entities[]', function* () {
      let {
        result
      } = yield this.api.queryView('entityViews/getEntities', this.fetchParams);
      return result;
    })
  });

  _exports.default = _default;
});