define("iris/models/entity", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "iris/mixins/custom-attributes", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _customAttributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('entity-presence'), (0, _emberCpValidations.validator)('length', {
      max: 150
    })],
    template: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['monitoring'],
      disabled: Ember.computed('model.monitoring', function () {
        return this.get('model.monitoring') ? false : true;
      }).volatile(),
      presence: true
    })],
    siteZone: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['monitoring'],
      disabled: Ember.computed('model.monitoring', function () {
        return this.get('model.monitoring') ? false : true;
      }).volatile(),
      presence: true
    })],
    partner: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', true)],
    manufacturer: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('entity-presence')],
    parent: [(0, _emberCpValidations.validator)('entity-presence')],
    type: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('entity-presence')],
    status: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('entity-presence')],
    entityValues: [(0, _emberCpValidations.validator)('has-many')],
    entityProcedures: [(0, _emberCpValidations.validator)('has-many')]
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, _customAttributes.default, {
    changeTracker: {
      trackHasMany: true,
      enableIsDirty: true,
      auto: true
    },
    mustDeletWhenUpdateMonitoring: 0,
    icingaStatus: (0, _attr.default)('string', {
      defaultValue: 'OK'
    }),
    modelName: 'Entity',
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    name: (0, _attr.default)('string'),
    template: (0, _attr.default)('string', {
      defaultValue: 'generic-host'
    }),
    barcode: (0, _attr.default)('string'),
    financialNumber: (0, _attr.default)('string'),
    qty: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    availableQty: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    expirationDate: (0, _attr.default)('date'),
    version: (0, _attr.default)('string'),
    virtual: (0, _attr.default)('number'),
    generateName: (0, _attr.default)('number'),
    serialNumber: (0, _attr.default)('string'),
    productKey: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    notificationDescription: (0, _attr.default)('string'),
    statusReason: (0, _attr.default)('string'),
    monitoring: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    wazuh: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    icinga2: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    isSatellite: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    monitoringStartDate: (0, _attr.default)('date'),
    customAttributes: (0, _attr.default)('string'),
    icingaConfigVersion: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    costPrice: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    parent: (0, _relationships.belongsTo)('entity', {
      inverse: 'children'
    }),
    children: (0, _relationships.hasMany)('entity', {
      inverse: 'parent'
    }),
    licensedEntities: (0, _relationships.hasMany)('entity', {
      inverse: 'licenses'
    }),
    licenses: (0, _relationships.hasMany)('entity', {
      inverse: 'licensedEntities'
    }),
    entityProcedures: (0, _relationships.hasMany)('entityProcedure', {
      inverse: 'entity'
    }),
    realEntityProcedures: (0, _relationships.hasMany)('entityProcedure', {
      inverse: 'realEntity'
    }),
    status: (0, _relationships.belongsTo)('entityStatus'),
    manufacturer: (0, _relationships.belongsTo)('entityManufacturer'),
    type: (0, _relationships.belongsTo)('entityProductType'),
    supplier: (0, _relationships.belongsTo)('supplier'),
    currency: (0, _relationships.belongsTo)('currency'),
    warrantyStart: (0, _attr.default)('date'),
    warrantyExpiration: (0, _attr.default)('date'),
    submitDate: (0, _attr.default)('date'),
    sapId: (0, _attr.default)('string'),
    remark: (0, _attr.default)('string'),
    partner: (0, _relationships.belongsTo)('partner'),
    contact: (0, _relationships.belongsTo)('contact'),
    accountable: (0, _relationships.belongsTo)('contact'),
    assignedContact: (0, _relationships.belongsTo)('contact'),
    assignedPartner: (0, _relationships.belongsTo)('partner'),
    contactType: (0, _relationships.belongsTo)('entityContactType'),
    inventoryOrganization: (0, _relationships.belongsTo)('entityInventoryOrganization'),
    siteZone: (0, _relationships.belongsTo)('siteZone'),
    location: (0, _relationships.belongsTo)('location'),
    entityType: (0, _relationships.belongsTo)('entityType'),
    attachedBusinessServices: (0, _relationships.hasMany)('businessService'),
    entityValues: (0, _relationships.hasMany)('entityValue', {
      inverse: 'entity'
    }),
    masterRelations: (0, _relationships.hasMany)('entityRelation', {
      inverse: 'masterEntity'
    }),
    slaveRelations: (0, _relationships.hasMany)('entityRelation', {
      inverse: 'slaveEntity'
    }),
    toDelete: Ember.A(),
    priorityCritical: (0, _attr.default)('number'),
    notificationCritical: (0, _relationships.belongsTo)('entityProcedureNotificationType'),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup'),
    documents: (0, _relationships.hasMany)('document', {
      polymorphic: true
    }),
    notificationGroups: (0, _relationships.hasMany)('notificationGroup'),
    changedEntityProcedures: Ember.computed('entityProcedures.@each.changed', function () {
      return this.get('entityProcedures').filter(ep => !!ep.changed);
    }),
    // Since change tracking is somewhat broken for has-many relationships, this
    // is a workaround for setting `isDirty` on the record. This is because a
    // record's own attribute always dirties the model, which will make ED perform
    // a save action on the related records. There are issues with boolean values,
    // so `Date` seems the safest way to create a dirty attribute. Note that this
    // property will be serialized and sent to the server.
    isDirtyTemp: (0, _attr.default)('date'),
    listedValues: Ember.computed({
      get() {
        let id = this.get('id');
        this.get('ajax').call('GET', 'entities', id, 'listed-values', {}).then(result => {
          this.set('listedValues', result.result);
        });
      },

      set(key, value) {
        return value;
      }

    }),

    copy() {
      let clone = this._super(...arguments);

      this.get('entityType').then(et => {
        if (et.get('entityTypeCategory.id').includes('000000002')) {
          clone.set('name', this.intl.t('cmdb.entities.new-software'));
        }
      });
      return clone;
    },

    icingaInfoRefreshIndicator: false,
    icingaInfo: Ember.computed('icingaInfoRefreshIndicator', {
      get() {
        let id = this.get('id');
        let name = this.get('name');
        this.get('ajax').call('GET', 'entities', id, 'get-icinga-info', {
          name: name
        }).then(result => {
          this.set('icingaInfo', result);
        });
      },

      set(key, value) {
        return value;
      }

    }),
    ownershipType: null,
    //technical property for contact->entity list
    uptime: Ember.computed.alias('icingaInfo.uptime.since'),
    upstate: Ember.computed.alias('icingaInfo.uptime.state'),
    actions: {
      move: function (selectedRecords) {
        var controller = this.controllerFor('cmdb.entity-types.index');
        controller.send('move', selectedRecords);
      },
      sync: function (selectedRecords) {
        var controller = this.controllerFor('cmdb.entity-types.index');
        controller.send('sync', selectedRecords);
      },
      start: function (selectedRecords) {
        var controller = this.controllerFor('cmdb.entity-types.index');
        controller.send('start', selectedRecords);
      },
      stop: function (selectedRecords) {
        var controller = this.controllerFor('cmdb.entity-types.index');
        controller.send('stop', selectedRecords);
      }
    }
  });

  _exports.default = _default;
});