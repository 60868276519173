define("iris/helpers/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
    @param1 string
    @param2 separator
    @return Array of elements
  
  */
  var _default = Ember.Helper.extend({
    compute(params) {
      const string = params[0];
      let separator = params[1];
      if (!separator) separator = ',';

      if (string) {
        return string.split(separator);
      } else {
        return [];
      }
    }

  });

  _exports.default = _default;
});