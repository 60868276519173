define("iris/components/beta/filters/fuzzy-search/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    columns: null,
    // @, Column[]
    _value: null,
    inputTitle: Ember.computed('columns.@each.displayName', function () {
      let columnNames = this.columns.map(_ref => {
        let {
          displayName
        } = _ref;
        return displayName;
      });
      return `Keresés a következőkben: ${columnNames.join(', ')}.`;
    }),

    init() {
      this._super(...arguments);

      if (this._value !== this.value) {
        Ember.set(this, '_value', this.value);
      }
    },

    actions: {
      onSubmit(event) {
        var _this$_value;

        event.preventDefault();
        const value = (_this$_value = this._value) === null || _this$_value === void 0 ? void 0 : _this$_value.trim();

        if (value) {
          this.onSubmit(value);
        }
      },

      clear() {
        Ember.set(this, '_value', null);
        this.onSubmit(null);
      }

    }
  });

  _exports.default = _default;
});