define("iris/helpers/project-type-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ProjectTypes = void 0;
  _exports.projectTypeValue = projectTypeValue;

  /**
   * Enum representing different types of projects
   */
  let ProjectTypes;
  /**
   * Helper function that converts a project type string to its corresponding numeric value
   * @param {string | undefined} type - The project type string to convert
   * @returns {number} The numeric value associated with the project type
   */

  _exports.ProjectTypes = ProjectTypes;

  (function (ProjectTypes) {
    ProjectTypes[ProjectTypes["normal"] = 0] = "normal";
    ProjectTypes[ProjectTypes["operational"] = 2] = "operational";
  })(ProjectTypes || (_exports.ProjectTypes = ProjectTypes = {}));

  function projectTypeValue(_ref) {
    let [type] = _ref;

    if (!type) {
      return ProjectTypes.normal;
    }

    const normalizedType = type.toLowerCase();
    return ProjectTypes[normalizedType];
  }

  var _default = Ember.Helper.helper(projectTypeValue);

  _exports.default = _default;
});