define("iris/components/change-requests/edit/tasks/affected-entities/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QcoU9tS6",
    "block": "{\"symbols\":[\"ae\",\"@data\",\"@close\"],\"statements\":[[5,\"change-requests/edit/tasks/affected-entities/data\",[],[[\"@model\"],[[23,2,[\"changeRequest\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[1,[28,\"t\",[\"operation.change-request.select-ci-elem\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"changeRequest\",\"project\",\"clientPartner\"]]],null,{\"statements\":[[0,\"        \"],[5,\"change-requests/edit/tasks/affected-entities/select\",[],[[\"@partner\",\"@model\",\"@onUpdateEntities\"],[[23,2,[\"changeRequest\",\"project\",\"clientPartner\"]],[23,2,[\"changeRequest\"]],[23,1,[\"updateEntities\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[5,\"bs-button\",[],[[\"@type\",\"@action\",\"@text\"],[\"default\",[23,3,[]],[28,\"t\",[\"general.close\"],null]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/tasks/affected-entities/modal/template.hbs"
    }
  });

  _exports.default = _default;
});