define("iris/controllers/operation/sd-dashboard/index", ["exports", "iris/lib/filters/types", "iris/services/query-params", "iris/models/ticket-state"], function (_exports, _types, _queryParams, _ticketState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const indexQueryParams = (0, _queryParams.makeFilterQueryParams)(null, {
    filters: [{
      name: 'ticketStateId',
      type: _types.FilterType.Equal,
      value: {
        id: _ticketState.TICKET_STATE_ID.Unresolved,
        name: _ticketState.TICKET_STATE_NAME.Unresolved,
        translation: true
      }
    }, {
      name: 'ticketStateId',
      type: _types.FilterType.Equal,
      value: {
        id: _ticketState.TICKET_STATE_ID.InProgress,
        name: _ticketState.TICKET_STATE_NAME.InProgress,
        translation: true
      }
    }, {
      name: 'ticketStateId',
      type: _types.FilterType.Equal,
      value: {
        id: _ticketState.TICKET_STATE_ID.WaitingForReporter,
        name: _ticketState.TICKET_STATE_NAME.WaitingForReporter,
        translation: true
      }
    }, {
      name: 'ticketStateId',
      type: _types.FilterType.Equal,
      value: {
        id: _ticketState.TICKET_STATE_ID.WaitingForThirdParty,
        name: _ticketState.TICKET_STATE_NAME.WaitingForThirdParty,
        translation: true
      }
    }, {
      name: 'ticketStateId',
      type: _types.FilterType.Equal,
      value: {
        id: _ticketState.TICKET_STATE_ID.WaitingForRelease,
        name: _ticketState.TICKET_STATE_NAME.WaitingForRelease,
        translation: true
      }
    }],
    order: {
      property: 'createDate',
      direction: 'desc'
    }
  });
  const queryParamsMixin = (0, _queryParams.makeControllerMixin)(indexQueryParams);

  var _default = Ember.Controller.extend(queryParamsMixin, {});

  _exports.default = _default;
});