define("iris/components/change-requests/edit/tasks/affected-entities/data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: null,
    // @
    actions: {
      async removeEntity(entityId) {
        const entities = await this.model.get('entities');

        if (hasEntity(entities.toArray(), entityId)) {
          const newEntities = entities.reject(entity => entity.id === entityId);
          await this.send('updateEntities', newEntities.toArray());
        }
      },

      /**
       * Updates multiple entities in bulk for a change request
       * @param {Array} selectedEntities - Array of entities to update
       * @returns {Promise<void>}
       */
      async updateEntities() {
        let selectedEntities = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        await this.model.save();
        const result = await this.api.collectionAction('entity', 'multiple-affected-entities', {
          method: 'POST',
          data: {
            direction: 'up',
            ids: selectedEntities.map(e => e.id),
            changeRequestId: this.model.id
          }
        });

        if (result !== null && result !== void 0 && result.success) {
          this.flashMessages.add({
            message: this.intl.t('operation.change-request.changeSuccess'),
            title: 'IRIS message',
            icon: 'info'
          });
          await Promise.all([this.model.reload(), this.model.entities.reload()]);
        } else if (result !== null && result !== void 0 && result.error) {
          this.flashMessages.add({
            type: 'danger',
            title: this.intl.t('operation.change-request.errorInRequest'),
            icon: 'warning'
          });
        }
      }

    }
  });

  _exports.default = _default;

  const hasEntity = (list, id) => list.some(e => e.id === id);
});