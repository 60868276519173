define("iris/utils/find-service-period", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://jsbin.com/qavilimiyo/6/edit?js,console
  class FindSl {
    constructor(date) {
      this.date = (0, _moment.default)(date);
      this.day = this.date.isoWeekday();
    }

    findPeriod() {
      const inSP = this.servicePeriods.filter(s => {
        return this.inServicePeriod(s);
      }); // date is not in service period, so let's find the closest

      if (inSP.length === 0) {
        return this.findClosestDay();
      } // date is at least in one service period
      // return the first match


      return inSP[0];
    }

    inServicePeriod(servicePeriod) {
      const {
        date,
        day
      } = this;
      const pattern = date.format('YYYY-MM-DD');

      if (servicePeriod.excDates.includes(pattern)) {
        return false;
      }

      if (!(servicePeriod.incDates.includes(pattern) || servicePeriod.days.includes(day))) {
        return false;
      }

      return this.inInterval(servicePeriod);
    }
    /**
     * checks if date is in a given service period's intervals
     */


    inInterval(servicePeriod) {
      const {
        strHourToIntHour,
        date
      } = this;
      const hours = date.hours();
      const minutes = date.minutes();
      const needle = hours + minutes / 60;
      let found = false;

      for (const interval of servicePeriod.intervals) {
        const start = strHourToIntHour(interval.start);
        const end = strHourToIntHour(interval.end);

        if (needle >= start && needle <= end) {
          found = true;
          break;
        }
      }

      return found;
    }

    findClosestDay() {
      let res = null;
      let distance = Infinity;
      const {
        strHourToIntHour,
        date,
        day
      } = this;

      for (const servicePeriod of this.servicePeriods) {
        const {
          incDates,
          days,
          excDates,
          intervals
        } = servicePeriod;
        const sortedIntervals = intervals.sort((a, b) => strHourToIntHour(a.start) - strHourToIntHour(b.start));
        const afterDates = incDates.filter(d => (0, _moment.default)(d).isAfter(date) || (0, _moment.default)(d).isSame(date, 'd')).sort();

        if (afterDates.length) {
          for (const interval of sortedIntervals) {
            const localDistance = (0, _moment.default)(afterDates[0]).add(strHourToIntHour(interval.start), 'h').diff(date, 'm');

            if (localDistance < distance && localDistance >= 0) {
              distance = localDistance;
              res = servicePeriod;
              break;
            }
          }
        }

        const afterDays = days.filter(d => d >= day);
        const beforeDays = days.filter(d => d < day);

        for (const d of afterDays.concat(beforeDays)) {
          const dayToAdd = d >= day ? d - day : d + 7 - day;
          const dayDate = this.date.clone().startOf('d').add(dayToAdd, 'd');

          if (!excDates.find(d => (0, _moment.default)(d).isSame(dayDate, 'd'))) {
            for (const interval of sortedIntervals) {
              const localDistance = dayDate.add(strHourToIntHour(interval.start), 'h').diff(date, 'm');

              if (localDistance < distance && localDistance >= 0) {
                distance = localDistance;
                res = servicePeriod;
                break;
              }
            }
          }
        }
      }

      return res;
    }
    /**
     * Converts a time string (08:30) to number 8.5
     */


    strHourToIntHour(str) {
      const [hours, minutes] = str.split(':');
      return Number(hours) + Number(minutes / 60);
    } // =============================================================


    async getServiceLevel(serviceLevels) {
      // if any of the service levels has no deadlines, then pick the first one
      const noDeadlines = serviceLevels.some(sl => sl.get('noDeadlines'));

      if (noDeadlines) {
        return serviceLevels[0];
      }

      const res = [];

      for (const serviceLevel of serviceLevels) {
        const servicePeriods = await this.getServicePeriods(serviceLevel);
        res.push(...servicePeriods);
      }

      if (res.length === 0) {
        return;
      }

      this.servicePeriods = res;
      const servicePeriod = this.findPeriod();
      return servicePeriod.serviceLevel;
    }

    async getServicePeriods(serviceLevel) {
      const res = [];
      let servicePeriods = await serviceLevel.get('servicePeriods');

      for (const servicePeriod of servicePeriods.toArray()) {
        const loadedServicePeriod = await this.loadServicePeriod(servicePeriod);
        loadedServicePeriod.serviceLevel = serviceLevel;
        res.push(loadedServicePeriod);
      }

      return res;
    }

    async loadServicePeriod(servicePeriod) {
      const intervals = await servicePeriod.get('servicePeriodIntervals');
      const dateType = await servicePeriod.get('dateType');
      const excludes = await dateType.get('excludes');
      const includes = await dateType.get('includes');
      const {
        excludesDateTypes,
        includesDateTypes,
        excludesDayTypes,
        includesDayTypes
      } = this.filterByType(includes, excludes);
      const excludesDates = await this.reduceDateTypes(excludesDateTypes);
      const includesDates = await this.reduceDateTypes(includesDateTypes);
      const days = this.getDays(excludesDayTypes, includesDayTypes);
      return {
        excDates: excludesDates,
        incDates: includesDates,
        days,
        intervals: intervals.map(i => ({
          start: i.get('start'),
          end: i.get('end')
        }))
      };
    }

    getDays(excludesDayTypes, includesDayTypes) {
      const excludesDays = this.reduceDays(excludesDayTypes);
      const includesDays = this.reduceDays(includesDayTypes);
      return includesDays.filter(day => {
        return excludesDays ? !excludesDays.includes(day) : true;
      });
    }

    async reduceDateTypes(dateTypes) {
      const res = [];

      for (const dataType of dateTypes) {
        const dates = await dataType.get('dates');
        const mappedDates = dates.map(d => (0, _moment.default)(d.get('date')).format('L'));
        res.concat(mappedDates);
      }

      return res;
    }

    filterByType(includes, excludes) {
      return {
        excludesDateTypes: excludes.toArray().filter(e => e.get('days') === false),
        includesDateTypes: includes.toArray().filter(e => e.get('days') === false),
        excludesDayTypes: excludes.toArray().filter(e => e.get('days') === true),
        includesDayTypes: includes.toArray().filter(e => e.get('days') === true)
      };
    } // https://jsbin.com/yifufatuco/3/edit?js,console


    reduceDays(dayTypes) {
      const week = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
      return dayTypes.reduce((prev, curr) => {
        const res = [];

        for (let i = 1; i <= week.length; i++) {
          const dayStr = week[i - 1];

          if (curr.get(dayStr) && !prev.includes(i)) {
            res.push(i);
          }
        }

        return prev.concat(res);
      }, []);
    }

  }

  _exports.default = FindSl;
});