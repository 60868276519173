define("iris/routes/finances/invoices/advance-invoice", ["exports", "iris/routes/finances/invoices/new", "iris/mixins/protected-route", "iris/utils/create-record"], function (_exports, _new, _protectedRoute, _createRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend(_protectedRoute.default, {
    templateName: 'finances/invoices/new',
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    async createInvoice(params) {
      const proformaInvoceId = params.invoice_id; // create new advance invoice

      if (proformaInvoceId === 'new') {
        return (0, _createRecord.createInvoice)(this.store, {
          region: this.sessionAccount.region
        });
      } // copy proforma invoice to advance invoice


      const {
        store
      } = this;
      const proformaInvoice = await store.findRecord('invoice', proformaInvoceId);
      const invoiceType = await store.findRecord('invoiceType', 'SYS_05'); // burnt in

      const advanceInvoice = await proformaInvoice.copy(true);
      advanceInvoice.set('invoiceType', invoiceType);
      advanceInvoice.set('datePaid', null);
      advanceInvoice.set('transactionId', null);
      advanceInvoice.set('navStatus', null);
      advanceInvoice.set('proformaInvoice', proformaInvoice);
      advanceInvoice.set('comment', this.intl.t('finances.main.made-from-proforma', {
        proformaAccountNumber: proformaInvoice.get('accountNumber')
      }));
      return advanceInvoice;
    },

    async setupController(controller, model) {
      this._super(controller, model);

      const {
        invoice
      } = model; // check if invoice is already an advance invoice

      if (invoice.belongsTo('invoiceType').id() !== 'SYS_05') {
        const advance = await this.get('store').find('invoiceType', 'SYS_05');
        invoice.set('invoiceType', advance);
        const item = invoice.get('items.firstObject');
        item.set('description', this.intl.t('finances.main.advance-invoice'));
      }

      controller.set('title', this.intl.t('finances.main.advance-invoice'));
    }

  });

  _exports.default = _default;
});