define("iris/models/file-outgoing-proposal-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "ember-cp-validations", "bankers-rounding"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _emberCpValidations, _bankersRounding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    quantity: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number-own')],
    price: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number-own')],
    description: (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    price: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    discount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountUntaxed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    defaultAmount: (0, _attr.default)('number'),
    defaultAmountUntaxed: (0, _attr.default)('number'),
    quantity: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    arrivalDate: (0, _attr.default)('date'),
    proposal: (0, _relationships.belongsTo)('fileOutgoingProposal'),
    vat: (0, _relationships.belongsTo)('vat'),
    uom: (0, _relationships.belongsTo)('uom'),
    currency: Ember.computed.alias('proposal.currency'),

    async recalculate() {
      const item = this;
      const proposal = item.get('proposal'); // get vatValue in two steps await item.get('vat.vatValue') does not work

      const vat = await item.get('vat');
      const vatValue = vat.get('vatValue');
      const roundDigits = await proposal.get('currency.round');
      const exchangeRate = await proposal.get('exchangeRate');
      let price = item.get('price') || 0;
      const quantity = item.get('quantity') || 1;
      const discount = item.get('discount'); // netto price before discount

      let nettoPrice = (0, _bankersRounding.default)(price * quantity, roundDigits);

      if (discount) {
        const discountAmount = -(discount / 100) * nettoPrice;
        nettoPrice = (0, _bankersRounding.default)(nettoPrice - discountAmount, roundDigits);
      }

      const amount = (0, _bankersRounding.default)(nettoPrice * (1 + vatValue), roundDigits);
      item.setProperties({
        amountUntaxed: nettoPrice,
        amount,
        defaultAmount: (0, _bankersRounding.default)(amount * exchangeRate, roundDigits),
        defaultAmountUntaxed: (0, _bankersRounding.default)(nettoPrice * exchangeRate, roundDigits)
      });
    }

  });

  _exports.default = _default;
});