define("iris/components/change-requests/edit/tasks/affected-entities/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ZY9Kv7x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb-12\"],[8],[0,\"\\n  \"],[5,\"beta/filters\",[],[[\"@storage\",\"@columns\",\"@bulkActions\"],[[23,0,[\"storage\"]],[23,0,[\"columns\"]],[23,0,[\"bulkActions\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"beta/grid\",[],[[\"@storage\",\"@columns\",\"@rows\",\"@isLoading\",\"@error\",\"@total\",\"@bulkActions\"],[[23,0,[\"storage\"]],[23,0,[\"columns\"]],[23,0,[\"entityViewResult\",\"data\"]],[23,0,[\"entityViewResultTask\",\"isRunning\"]],[23,0,[\"entityViewResultTask\",\"error\"]],[23,0,[\"entityViewResult\",\"count\"]],[23,0,[\"bulkActions\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/change-requests/edit/tasks/affected-entities/select/template.hbs"
    }
  });

  _exports.default = _default;
});