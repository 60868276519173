define("iris/components/cmdb-visualisation2/component", ["exports", "iris/mixins/cmdb-visualisation/navigation", "iris/mixins/cmdb-visualisation/events", "iris/mixins/cmdb-visualisation/shapes", "iris/mixins/cmdb-visualisation/animation", "iris/mixins/cmdb-visualisation/minimap", "iris/mixins/cmdb-visualisation/layout", "iris/mixins/cmdb-visualisation/config", "underscore", "iris/lib/modals"], function (_exports, _navigation, _events, _shapes, _animation, _minimap, _layout, _config, _underscore, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_navigation.default, _shapes.default, _animation.default, _minimap.default, _layout.default, _events.default, _config.default, {
    modals: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    // Lazy load this using `this.joint = await import('jointjs').default` to avoid
    // jointjs to be bundled with the vendor code.
    joint: null,

    async didInsertElement() {
      this._super(...arguments);

      const el = this.$('div.holder', this);
      const parent = this.$().parent();
      this.set('holderElement', el);
      el.height(parent.height());
      el.width(parent.width());
      this.joint = await emberAutoImportDynamic("jointjs").default;
      await this.setConfig();
      const graph = new this.joint.dia.Graph();
      const paper = new this.joint.dia.Paper({
        el: el,
        model: graph,
        width: el.width(),
        height: el.height(),
        gridSize: 1,
        defaultConnectionPoint: {
          name: 'boundary',
          args: {
            stroke: true
          }
        }
      });
      this.set('graph', graph);
      this.set('paper', paper);
      graph.on('remove', (cell, collection, opt) => {
        if (cell.isLink()) {// a link was removed  (cell.id contains the ID of the removed link)
        }

        if (cell.isElement()) {}
      });
      this.initShapes(this.joint, this.get('config'), graph);
      await this.getEntities([this.model.id]);
      this.layoutGraph();
      this.bindEvents();
      this.initNavigation(el[0]);
    },

    async queryEntities(ids) {
      return await this.store.query('entity', {
        filter: {
          where: {
            id: {
              inq: ids
            }
          },
          include: ['masterRelations', 'slaveRelations', 'entityType']
        }
      });
    },

    async getEntities(ids) {
      const entities = await this.queryEntities(ids);

      for (const entity of entities.toArray()) {
        const element = await this.makeElement(entity);
        await this.addElementToGraph(element);
      }
    },

    async makeElement(entity) {
      const entityType = await entity.get('entityType');
      const categoryId = entityType.belongsTo('entityTypeCategory').id();
      const slaveRelations = entity.hasMany('slaveRelations').value();
      const slaveEntityIds = slaveRelations.map(r => r.belongsTo('masterEntity').id());
      const masterRelations = entity.hasMany('masterRelations').value();
      const masterEntityIds = masterRelations.map(r => r.belongsTo('slaveEntity').id());
      return {
        id: entity.id,
        name: entity.get('name'),
        type: entityType.get('name'),
        typeId: entityType.id,
        category: categoryId.substring(3),
        opaque: false,
        threshold: 0.5,
        slaveRelations,
        slaveEntityIds,
        masterRelations,
        masterEntityIds,
        state: entity.get('icingaStatus')
      };
    },

    async addElementToGraph(element) {
      let safe = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const {
        config,
        graph,
        paper
      } = this;
      let elem;

      if (safe === true && this.findElementByIid(element.id)) {
        return;
      }

      switch (element.category) {
        case '000000004':
          elem = this.config.name === 'classic' ? new this.joint.shapes.iris.Cog() : new this.joint.shapes.iris.Icon();
          break;

        case '000000005':
          elem = this.config.name === 'classic' ? new this.joint.shapes.iris.Cert() : new this.joint.shapes.iris.Icon();
          break;

        default:
          elem = this.config.name === 'classic' ? new this.joint.shapes.iris.Rectangle() : new this.joint.shapes.iris.Icon();
      }

      if (this.config.name !== 'classic') {
        const entityTypes = await this.store.query('entityType', {
          filter: {
            where: {
              id: element.typeId
            },
            include: ['entityTypeVisualisation']
          }
        });

        if (entityTypes.length) {
          const entityTypeVisualisation = entityTypes.get('firstObject').belongsTo('entityTypeVisualisation').value();

          if (entityTypeVisualisation) {
            elem.attr('image/xlink:href', 'data:image/svg+xml;utf8,' + encodeURI(entityTypeVisualisation.get('svg')));
          }
        }
      }

      elem.resize(120, 90);
      elem.prop('state/value', config.stateDictionary[element.state]);
      elem.prop('data', element);
      elem.attr({
        iid: element.id,
        ciCategory: element.category,
        body: {
          fill: '#' + config.ciCategory[element.category],
          stroke: config.defaultStrokes.element
        }
      });

      if (config.wrapLabel) {
        elem.attr('label/textWrap', {
          text: `${element.name}`,
          ellipsis: true,
          width: -20
        });
      } else {
        elem.attr('label/text', element.name);
      }

      elem.attr('typeBoxText/textWrap', {
        text: element.type,
        ellipsis: true,
        width: -20
      });
      elem.attr('image/title', element.type);

      if (element.masterEntityIds.length === 0) {
        elem.hideCollapse('master');
      }

      if (element.slaveEntityIds.length === 0) {
        elem.hideCollapse('slave');
      }

      elem.addTo(graph);
      elem.updateLinkStats(this.graph);
      elem.findView(paper).$el.find('[joint-selector=collapseTop], [joint-selector=collapseTopSign], [joint-selector=masterLinkStats]').click(() => {
        this.linkStatsClicked(elem, 'master');
      });
      elem.findView(paper).$el.find('[joint-selector=collapseBottom], [joint-selector=collapseBottomSign], [joint-selector=slaveLinkStats]').click(() => {
        this.linkStatsClicked(elem, 'slave');
      });
      return elem;
    },

    async linkStatsClicked(elem, direction) {
      const alreadySelectedEntityIds = elem.get(`${direction}SelectedEntityIds`) || [];
      const selectedEntities = await this.openModal(elem.get('data')[`${direction}EntityIds`], alreadySelectedEntityIds, elem.get('data').id, direction); // modal closed with cancel

      if (selectedEntities === false) {
        return;
      }

      const selectedIds = selectedEntities.map(entity => entity.id); // remove unselected elemets

      const toRemoveIds = alreadySelectedEntityIds.filter(id => !selectedIds.includes(id));
      const options = direction === 'master' ? {
        outbound: true
      } : {
        inbound: true
      };

      if (toRemoveIds.length) {
        this.graph.getConnectedLinks(elem, options).map(link => direction === 'master' ? link.getTargetElement() : link.getSourceElement()).filter(element => toRemoveIds.includes(element.get('data').id)).forEach(element => {
          element.remove();
        });
      } // add elements


      const toAddIds = selectedIds.filter(id => !alreadySelectedEntityIds.includes(id));
      await this.getEntities(toAddIds);
      await this.addLinks(elem, toAddIds, direction);
      elem.updateLinkStats(this.graph);
      this.layoutGraph();
    },

    /**
     *
     *
     * @param {JointJs elem} element The source of the link
     * @param {string} [direction='slave'] slave | master direction of the link,
     */
    async addLinks(element, selectedIds) {
      let direction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'slave';
      const relations = element.get('data')[`${direction}Relations`].toArray();
      const oppositeDirection = this.oppositeDirection(direction);

      for (const relation of relations) {
        const id = relation.belongsTo(`${oppositeDirection}Entity`).id();

        if (selectedIds.includes(id)) {
          await this.createLink(relation, element, direction);
        }
      }
    },

    oppositeDirection(direction) {
      return direction === 'slave' ? 'master' : 'slave';
    },

    /**
     * Links source with target. If target has relation from another elements it connects them as well,
     * but only if it is already loaded to the graph
     *
     *  source-->target<--elemX
     *     elemY---^ ^---------------elemZ
     *
     * @param {*} relation ED record, CMDB relation model
     * @param {*} source JointJs element
     * @param {String} direction slave | master
     */
    async createLink(relation, source, direction) {
      const oppositeDirection = this.oppositeDirection(direction);
      const targetId = relation.belongsTo(`${oppositeDirection}Entity`).id();
      let target = this.findElementByIid(targetId);

      if (!target) {
        return;
      }

      const relationType = await relation.get('entityRelationType'); // check if target's relations are already loaded
      // filter out the source's relation

      const relations = target.get('data')[`${oppositeDirection}Relations`].filter(relation => relation.belongsTo(`${direction}Entity`).id() !== source.get('data').id);

      for (const relation of relations) {
        const id = relation.belongsTo(`${direction}Entity`).id();
        let found = this.findElementByIid(id);

        if (found) {
          const relationType = await relation.get('entityRelationType');

          if (direction === 'slave') {
            this.addLink(target, found, relationType.get('name'));
          } else {
            this.addLink(found, target, relationType.get('name'));
          }
        }
      } // switcheroo


      if (direction === 'slave') {
        this.addLink(target, source, relationType.get('name'));
      } else {
        this.addLink(source, target, relationType.get('name'));
      }
    },

    elemHideCollapseSign(elem) {
      const masterIds = elem.get('data').masterEntityIds;
      const targetIds = this.graph.getConnectedLinks(elem, {
        outbound: true
      }).map(link => link.getTargetElement().get('data').id);

      if (masterIds.length === targetIds.length) {
        elem.hideCollapse('master');
      }

      const slaveIds = elem.get('data').slaveEntityIds;
      const sourceIds = this.graph.getConnectedLinks(elem, {
        inbound: true
      }).map(link => link.getSourceElement().get('data').id);

      if (slaveIds.length === sourceIds.length) {
        elem.hideCollapse('slave');
      }
    },

    addLink(source, target, relationName) {
      // chek if link already exists
      // TODO /w toAddIds check it should be unnecessary. remove it
      if (this.graph.getConnectedLinks(source, {
        outbound: true
      }).find(link => link.getTargetElement() === target)) {
        return;
      }

      const link = new this.joint.shapes.standard.Link();
      link.target(target);
      link.source(source);
      link.attr({
        relationType: relationName,
        line: {
          stroke: this.config.defaultStrokes.link.color,
          strokeWidth: this.config.defaultStrokes.link.width
        }
      });
      link.labels([{
        attrs: {
          text: {
            text: relationName
          }
        }
      }]);
      link.connector('rounded', {
        radius: 10
      });
      link.addTo(this.graph);
      link.toBack();
      const sourceId = source.get('data').id;
      let selectedEntityIds = target.get('slaveSelectedEntityIds') ? target.get('slaveSelectedEntityIds').concat([sourceId]) : [sourceId];
      target.set('slaveSelectedEntityIds', [...new Set(selectedEntityIds)]);
      const targetId = target.get('data').id;
      selectedEntityIds = source.get('masterSelectedEntityIds') ? source.get('masterSelectedEntityIds').concat([targetId]) : [targetId];
      source.set('masterSelectedEntityIds', [...new Set(selectedEntityIds)]);
      target.updateLinkStats(this.graph);
      source.updateLinkStats(this.graph);
      return link;
    },

    findElementByIid(iid) {
      return _underscore.default.find(this.get('graph').getElements(), elem => {
        return iid === elem.attr('iid');
      });
    },

    async openModal(ids, alreadySelectedEntityIds, id, direction) {
      // check for unique entityType ids. unfortunately this is necessary,
      // because 'entityViews/getEntities' needs it beforehand to include in the result
      // the entityType specific (listable) columns
      // Turn off because it generate very big queries
      // Chrome stops displaying the URL after 64k characters, but can serve more than 100k characters. No further testing was done beyond that.
      // Note: The maximum length is actually 2MB, and the display length is 32kB, as per the Chromium Docs referenced below.
      // const entities = await this.queryEntities(ids);
      // const entityTypeIds = entities.map((entity) =>
      //   entity.belongsTo('entityType').id()
      // );
      // const uniqEntityTypeIds = new Set(entityTypeIds);
      // const entityTypeId =
      //   uniqEntityTypeIds.size === 1
      //     ? uniqEntityTypeIds.values().next().value
      //     : null;
      const selectedEntities = await this.modals.open('cmdb-visualisation2/modal', {
        id,
        direction,
        // entityTypeId,
        alreadySelectedEntityIds
      }, {
        className: _modals.ModalLayoutClassName.XXL
      });

      if (!selectedEntities) {
        return false;
      }

      return selectedEntities;
    }

  });

  _exports.default = _default;
});