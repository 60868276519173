define("iris/components/forms/file-outgoing-proposal", ["exports", "iris/utils/properties", "bankers-rounding", "iris/utils/finances", "iris/utils/model", "iris/lib/modals", "moment"], function (_exports, _properties, _bankersRounding, _finances, _model, _modals, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    loading: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    modals: Ember.inject.service(),
    step: 1,
    billableProductResults: Ember.A(),
    notBillableProductResults: Ember.A(),
    defaultCurrency: null,
    currencyChange: true,

    async init() {
      this._super();

      const currency = await (0, _finances.getSessionDefaultCurrency)(this.sessionAccount);
      this.set('defaultCurrency', currency);
      const region = this.get('sessionAccount.region');
      let where = {
        and: [{
          regionId: region.id
        }, {
          settingKey: 'outgoingProposalCurrencyChange'
        }]
      };
      let settings = await this.get('store').query('regionalSetting', {
        filter: {
          where
        }
      });

      if (settings.length > 0) {
        const setting = settings.firstObject;
        const currencyChange = setting.settingValue === 'false' ? false : true;
        this.set('currencyChange', currencyChange);
      }

      if (!this.model.id) {
        const billableProductResults = Ember.A();
        const notBillableProductResults = Ember.A();
        const productResults = await this.model.get('file.productResults');

        for (const pr of productResults.toArray()) {
          if (pr.billable) {
            billableProductResults.pushObject(pr);
          } else {
            notBillableProductResults.pushObject(pr);
          }
        }

        this.set('billableProductResults', billableProductResults);
        this.set('notBillableProductResults', notBillableProductResults);
      } else {
        this.set('step', 2);
      }
    },

    tariff: (0, _properties.computedTask)('model.id', function* () {
      if (!this.model.id) {
        return 0;
      }

      const response = yield this.get('ajax').call('GET', 'file-outgoing-proposals', this.model.id, 'get-tariff');
      return response;
    }),

    async _getExchangeRate(currency) {
      const code = currency.get('code').trim();
      let exchangeRate = 1;

      if (code !== 'HUF') {
        const response = await this.get('ajax').call('GET', 'currencies', '', 'get-exchange-rate', {
          currency: code
        });
        exchangeRate = response.value;
      }

      this.model.set('exchangeRate', exchangeRate);
      this.model.set('exchangeRateContract', exchangeRate);
      currency.set('exchangeRate', exchangeRate);
    },

    async _changeCurrency(currency) {
      this.model.set('currency', currency);
      const roundingDigits = currency.get('round');
      const prevExchangeRate = this.model.get('exchangeRate');
      await this._getExchangeRate(currency);
      const exchangeRate = this.model.get('exchangeRate');
      const items = await this.model.get('items');

      for (const item of items.toArray()) {
        if (this.currencyChange === true) {
          item.set('price', (0, _bankersRounding.default)(item.get('price') * (prevExchangeRate / exchangeRate), roundingDigits));
        }

        await item.recalculate();
      }

      await this.model.recalculate();
    },

    actions: {
      async openWorklogDisplayModal(worklog) {
        await this.modals.open('modals/work-log-display-modal', {
          worklog
        }, {
          className: _modals.ModalLayoutClassName.S
        });
      },

      async changeExchangeRateContract(vat) {
        const exchangeRate = await this.modals.open('modals/proposal-item-change-exchange-rate', {
          exchangeRate: this.model.get('exchangeRateContract')
        }, {
          className: _modals.ModalLayoutClassName.S
        });

        if (exchangeRate && this.model.exchangeRateContract) {
          this.model.set('exchangeRateContract', exchangeRate);
        }
      },

      async changeExchangeRate(vat) {
        const exchangeRate = await this.modals.open('modals/proposal-item-change-exchange-rate', {
          exchangeRate: this.model.get('exchangeRate')
        }, {
          className: _modals.ModalLayoutClassName.S
        });

        if (exchangeRate && this.model.exchangeRate) {
          this.model.setProperties({
            defaultAmount: this.model.defaultAmount * (exchangeRate / this.model.exchangeRate),
            defaultAmountUntaxed: this.model.defaultAmountUntaxed * (exchangeRate / this.model.exchangeRate)
          });
          const items = await this.model.get('items');

          for (const item of items.toArray()) {
            const defaultAmount = item.get('defaultAmount');
            item.setProperties({
              defaultAmount: item.defaultAmount * (exchangeRate / this.model.exchangeRate),
              defaultAmountUntaxed: item.defaultAmountUntaxed * (exchangeRate / this.model.exchangeRate)
            });
          }

          this.model.set('exchangeRate', exchangeRate);
        }
      },

      async update() {
        const {
          model,
          validations
        } = await (0, _model.validateRecord)(this.model);

        if (!validations.isValid) {
          model.setValidated();
          return;
        } else {
          model.setValidated();
        } // check if items are also valid


        let items = await this.model.get('items');
        items = items.toArray();

        for (const item of items) {
          const {
            model,
            validations
          } = await (0, _model.validateRecord)(item);
          item.setValidated();

          if (!validations.isValid) {
            return;
          }
        }

        await this.loading.run(async () => {
          await this.model.save();

          for (const item of items) {
            await item.save();
          }
        });
        this.close(true);
      },

      cancel() {
        this.close();
      },

      async next() {
        const store = this.store;
        let defaultVat = null;
        await this.loading.run(async () => {
          let defaultCurrency = this.defaultCurrency;

          if (this.model.file.get('type') !== 'normal') {
            defaultCurrency = await this.model.file.get('contract.valuePayment');
          }

          await this._getExchangeRate(defaultCurrency);
          const vats = await store.findAll('vat');
          vats.forEach(vat => {
            if (vat.get('id') === 'SYS_03') {
              defaultVat = vat;
            }
          });
          this.model.set('currency', defaultCurrency);
          const items = await this.model.get('items');
          const productResults = await this.model.file.get('productResults');
          this.model.set('fileProductResults', this.billableProductResults);

          for (const productResult of this.billableProductResults.toArray()) {
            let record = store.createRecord('fileOutgoingProposalItem', {
              name: productResult.get('name'),
              description: productResult.get('comment'),
              price: productResult.get('amount'),
              discount: 0,
              amount: productResult.get('amount'),
              vat: defaultVat,
              amountUntaxed: productResult.get('amount'),
              defaultAmount: Math.round(productResult.get('amount') * this.model.get('exchangeRate')),
              proposal: this.model
            });
            await record.recalculate();
            items.pushObject(record);
          }

          this.model.recalculate();
        });
        this.set('step', 2);
      },

      removeProductResult(pr) {
        this.billableProductResults.removeObject(pr);
        this.notBillableProductResults.pushObject(pr);
      },

      addProductResult(pr) {
        this.billableProductResults.pushObject(pr);
        this.notBillableProductResults.removeObject(pr);
      },

      async changeCurrency(currency) {
        await this._changeCurrency(currency);
      },

      updateEndDate(days) {
        this.model.set('validInDays', days);

        if (!days) {
          return;
        }

        const endDate = (0, _moment.default)(this.model.fromDate).clone().add(days, 'd');
        this.model.set('endDate', endDate);
      }

    }
  });

  _exports.default = _default;
});