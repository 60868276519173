define("iris/models/ticket", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data/relationships", "iris/models/ticket-state", "moment", "underscore", "iris/mixins/anonymize"], function (_exports, _model, _emberCpValidations, _relationships, _ticketState, _moment, _underscore, _anonymize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    subject: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 250
    })],
    plannedExpensesInHour: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    state: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    ticketSource: [(0, _emberCpValidations.validator)('belongs-to', {
      dependentKeys: ['model.file'],
      disabled: Ember.computed('model', function () {
        return !this.get('model.isOperational');
      }).volatile()
    }), (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.file', 'model.state'],
      disabled: Ember.computed('model', function () {
        // preTicket does not need it
        if (this.get('model.state.id') === 'SYS_17' || this.get('model.state.id') === 'SYS_19') {
          return false;
        }

        return !this.get('model.isOperational');
      }).volatile(),
      presence: true
    })],
    description: (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.file'],
      disabled: Ember.computed('model.file.type', function () {
        return !this.get('model.isOperational');
      }).volatile(),
      presence: true
    }),
    type: [(0, _emberCpValidations.validator)('belongs-to', {
      dependentKeys: ['model.file'],
      disabled: Ember.computed('model', function () {
        return !this.get('model.isOperational');
      }).volatile()
    }), (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.file', 'model.state'],
      disabled: Ember.computed('model.state.id', function () {
        // preTicket does not need it
        if (this.get('model.state.id') === 'SYS_17' || this.get('model.state.id') === 'SYS_19') {
          return true;
        }

        return !this.get('model.isOperational');
      }).volatile(),
      presence: true
    })],
    resolver: (0, _emberCpValidations.validator)('ticket-resolver', {
      dependentKeys: ['model.file', 'model.resolver', 'model.solvingGroup', 'model.type'],
      disabled: Ember.computed('model.state.id', 'model.type.id', 'model.file.type', function () {
        // RBK and preticket  does not need it
        if (this.get('model.type.id') === 'SYS_16' || (this.get('model.state.id') === 'SYS_17' || this.get('model.state.id') === 'SYS_19') && !this.get('model.type.id')) {
          return true;
        }

        return !this.get('model.isOperational');
      }).volatile()
    }),
    serviceLevel: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('belongs-to', {
      dependentKeys: ['model.file'],
      disabled: Ember.computed('model', function () {
        return !this.get('model.isOperational');
      }).volatile()
    }), (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.file', 'model.type', 'model.state'],
      disabled: Ember.computed('model.state.id', 'model.type.id', function () {
        // RBK and preticket does not need it
        if (this.get('model.type.id') === 'SYS_16' || this.get('model.state.id') === 'SYS_17' || this.get('model.state.id') === 'SYS_19') {
          return true;
        } // Project


        return !this.get('model.isOperational');
      }).volatile(),
      presence: true
    })],
    file: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      disabled: Ember.computed('model', function () {
        return this.model.isNew ? true : false;
      }).volatile(),
      presence: true
    })],
    currentReason: [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.file', 'model.state', 'model.type'],
      disabled: Ember.computed('model.state.id', 'model.stateChanged', 'model.type.id', function () {
        // RBK does not need it
        if (this.get('model.type.id') === 'SYS_16' || this.get('model.state.id') !== _ticketState.TICKET_STATE_ID.WaitingForReporter && this.get('model.state.id') !== _ticketState.TICKET_STATE_ID.WaitingForThirdParty && this.get('model.state.id') !== _ticketState.TICKET_STATE_ID.Rejected || !this.get('model.stateChanged')) {
          return true;
        }

        return !this.get('model.isOperational');
      }).volatile(),
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 15
    })],
    pst: [(0, _emberCpValidations.validator)('length', {
      max: 512
    })],
    completionLevel: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      positive: true,
      lte: 100,
      gte: 0
    })]
  });

  var _default = _model.default.extend(Validations, _anonymize.default, {
    modelName: 'Ticket',
    changeTracker: {
      except: ['createUser', 'file', 'type', 'workLogs', 'ticketUserAssocs']
    },
    // settings for user models
    subject: (0, _model.attr)('string'),
    ticketNumber: (0, _model.attr)('string'),
    plannedExpensesInHour: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    alreadyAssignedTimes: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    priority: (0, _model.attr)('number', {
      defaultValue: 3
    }),
    orderIndex: (0, _model.attr)('number'),
    description: (0, _model.attr)('string'),
    completionLevel: (0, _model.attr)('number'),
    conditions: (0, _model.attr)('string'),
    plannedStartDate: (0, _model.attr)('date'),
    plannedEndDate: (0, _model.attr)('date'),
    createDate: (0, _model.attr)('date'),
    ticketSourceId: (0, _model.attr)('string'),
    ticketTypeId: (0, _model.attr)('string'),
    ticketStateId: (0, _model.attr)('string'),
    contactId: (0, _model.attr)('string'),
    calendar: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    workLogNotification: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    deletedAt: (0, _model.attr)('date'),
    impactOfChange: (0, _model.attr)('number'),
    checklist: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    billable: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    maxHoursPerWorkLog: (0, _model.attr)('number', {
      defaultValue: 10
    }),
    callAfterBaseHours: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    resolverId: (0, _model.attr)('string'),
    solvingGroupId: (0, _model.attr)('string'),
    contactDescription: (0, _model.attr)('string'),
    remoteAccess: (0, _model.attr)('string'),
    serviceRating: (0, _model.attr)('number'),
    reportCloseMotivation: (0, _model.attr)('string'),
    reportCloseProposal: (0, _model.attr)('string'),
    startDeadline: (0, _model.attr)('date'),
    solveDeadline: (0, _model.attr)('date'),
    closeTime: (0, _model.attr)('date'),
    reactionDeadline: (0, _model.attr)('date'),
    ticketAuditListId: (0, _model.attr)('string'),
    subticket: (0, _model.attr)('number'),
    violationReaction: (0, _model.attr)('number'),
    violationStart: (0, _model.attr)('number'),
    violationSolve: (0, _model.attr)('number'),
    originalReaction: (0, _model.attr)('number'),
    originalStart: (0, _model.attr)('number'),
    originalSolve: (0, _model.attr)('number'),
    mergedParentId: (0, _model.attr)('string'),
    currentReason: (0, _model.attr)('string'),
    stateChanged: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    oldStateId: (0, _model.attr)('string'),
    imacd: (0, _model.attr)('boolean'),
    tandm: (0, _model.attr)('boolean'),
    isClient: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    pst: (0, _model.attr)('string'),
    isOperational: (0, _model.attr)('boolean'),
    //operational property seems to used by ember
    securityIncident: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    type: (0, _relationships.belongsTo)('ticketType'),
    supplierPartner: (0, _relationships.belongsTo)('partner'),
    state: (0, _relationships.belongsTo)('ticketState'),
    workplaceAddress: (0, _relationships.belongsTo)('address'),
    plannedUserGrade: (0, _relationships.belongsTo)('userGrade'),
    file: (0, _relationships.belongsTo)('project'),
    productResult: (0, _relationships.belongsTo)('fileProductResult'),
    parent: (0, _relationships.belongsTo)('ticket', {
      inverse: 'children'
    }),
    contact: (0, _relationships.belongsTo)('contact'),
    serviceArea: (0, _relationships.belongsTo)('serviceArea'),
    solvingGroup: (0, _relationships.belongsTo)('solvingGroup'),
    resolver: (0, _relationships.belongsTo)('account'),
    referenceTicket: (0, _relationships.belongsTo)('ticket', {
      inverse: null
    }),
    serviceLevel: (0, _relationships.belongsTo)('serviceLevel'),
    ticketAuditList: (0, _relationships.belongsTo)('ticketAuditList'),
    createUser: (0, _relationships.belongsTo)('account'),
    operationServiceLevel: (0, _relationships.belongsTo)('operationServiceLevel'),
    businessService: (0, _relationships.belongsTo)('businessService'),
    entityType: (0, _relationships.belongsTo)('entityType'),
    ticketSource: (0, _relationships.belongsTo)('ticketSource'),
    mergedParent: (0, _relationships.belongsTo)('ticket', {
      inverse: null
    }),
    repairForm: (0, _relationships.belongsTo)('repairForm'),
    activeTandm: (0, _relationships.belongsTo)('tandm'),
    aiopsTicket: (0, _relationships.belongsTo)('aiopsTicket'),
    documents: (0, _model.hasMany)('document'),
    comments: (0, _model.hasMany)('comment'),
    children: (0, _model.hasMany)('ticket', {
      inverse: 'parent'
    }),
    ticketUserAssocs: (0, _model.hasMany)('ticketUserAssoc'),
    ticketPlannerAssocs: (0, _model.hasMany)('account'),
    dependencies: (0, _model.hasMany)('ticket', {
      inverse: null
    }),
    dependents: (0, _model.hasMany)('ticket', {
      inverse: null
    }),
    ticketUsers: Ember.computed.mapBy('ticketUserAssocs', 'user'),
    ticketSuppliers: Ember.computed.mapBy('ticketUserAssocs', 'supplier'),
    workLogs: (0, _model.hasMany)('workLog'),
    //slaTimes: hasMany('slaTime'),
    entities: (0, _model.hasMany)('entity'),
    toDelete: {
      ticketUser: Ember.A()
    },
    colors: ['#FF000D', '#FFA4A8', '#FF7077', '#D5000B', '#A40008', '#FF6F00', '#FFCBA4', '#FFAE70', '#D95E00', '#A74800', '#00FFEC', '#A4FFF8', '#70FFF5', '#00857C', '#00675F', '#18FF00', '#ACFFA4', '#7EFF70', '#11B200', '#0D8900'],
    color: Ember.computed('colors', 'ticketNumber', function () {
      if (this.ticketNumber) {
        return this.colors[parseInt(this.ticketNumber.slice(-1))];
      } else {
        this.colors[0];
      }
    }),
    noDeadlines: Ember.computed.alias('serviceLevel.noDeadlines'),
    dateStart: Ember.computed.alias('plannedStartDate'),
    dateEnd: Ember.computed.alias('plannedEndDate'),
    workLogsWithId: Ember.computed.filter('workLogs.@each.id', function (workLog) {
      return !!workLog.get('id');
    }),
    workLogCosts: Ember.computed.mapBy('workLogs.content', 'cost'),
    cost: Ember.computed.sum('workLogCosts'),
    workLogOverheadCosts: Ember.computed.mapBy('workLogs.content', 'rawCost'),
    overheadCost: Ember.computed.sum('workLogOverheadCosts'),
    alreadyAssignedTime: Ember.computed('ticketUserAssocs.@each.plannedExpensesInHour', {
      get(key) {
        let allTimeSum = 0;

        if (this.get('ticketUserAssocs.length')) {
          allTimeSum = this.ticketUserAssocs.reduce((prev, current) => {
            prev = prev ? prev : 0;
            return prev + parseFloat(current.get('plannedExpensesInHour'));
          });
        }

        return allTimeSum;
      },

      set(key, value) {
        if (value) {
          return value;
        } else {
          return 0;
        }
      }

    }),
    gone: Ember.computed('alreadyAssignedTime', 'plannedExpensesInHour', function () {
      let plannedExpensesInHour = this.plannedExpensesInHour;
      let alreadyAssignedTime = this.alreadyAssignedTime;
      let value = false;

      if (alreadyAssignedTime > plannedExpensesInHour) {
        value = true;
      }

      return value;
    }),
    availableTimeToAssign: Ember.computed('alreadyAssignedTime', 'plannedExpensesInHour', function () {
      let plannedExpensesInHour = this.plannedExpensesInHour;
      let alreadyAssignedTime = this.alreadyAssignedTime;
      let value = 0;

      if (alreadyAssignedTime) {
        value = plannedExpensesInHour - alreadyAssignedTime;
      } else if (plannedExpensesInHour) {
        value = plannedExpensesInHour;
      } else {
        value = 0;
      }

      return Math.round(value * 100) / 100;
    }),
    plannedBudgetMoney: Ember.computed('plannedUserGrade', 'plannedExpensesInHour', 'file.fileUserGradeCosts.@each.cost', {
      get(key) {
        var _this = this,
            value = 0;

        this.plannedUserGrade.then(function (plannedUserGrade) {
          if (plannedUserGrade) {
            _this.get('file').then(function (file) {
              file.get('fileUserGradeCosts').then(function (fileUserGradeCosts) {
                let valid = fileUserGradeCosts.findBy('userGrade.id', plannedUserGrade.get('id'));

                if (valid) {
                  let cost = _this.get('plannedExpensesInHour') * valid.get('plannedCost');

                  _this.set('plannedBudgetMoney', cost);

                  value = cost;
                }
              });
            });
          }
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    userAssocs: Ember.computed.filter('ticketUserAssocs', function (tua) {
      return tua.belongsTo('user').id();
    }),
    partnerAssocs: Ember.computed.filter('ticketUserAssocs', function (tua) {
      return tua.belongsTo('supplier').id();
    }),
    assaignes: Ember.computed.mapBy('userAssocs', 'user'),
    supplierAssaignes: Ember.computed.mapBy('partnerAssocs', 'supplier'),
    //cost: computed.alias('plannedUserGrade.cost')
    resolvedWorkLogs: Ember.computed('workLogs.[]', {
      get(key) {
        let _this = this;

        let value = Ember.A();
        this.workLogs.then(workLogs => {
          _this.set('resolvedWorkLogs', workLogs);

          value = workLogs;
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    mappedPublicSpentTime: Ember.computed.mapBy('workLogs', 'publicSpentTime'),
    mappedSpentTime: Ember.computed.mapBy('workLogs', 'spentTime'),
    sumPublicSpentTime: Ember.computed.sum('mappedPublicSpentTime'),
    sumSpentTime: Ember.computed.sum('mappedSpentTime'),
    allSpentTime: Ember.computed('sumPublicSpentTime', 'sumSpentTime', function () {
      return this.sumPublicSpentTime + this.sumSpentTime;
    }),
    availableSpentTimes: Ember.computed('allSpentTime', 'plannedExpensesInHour', function () {
      return this.plannedExpensesInHour - this.allSpentTime;
    }),
    resolvedFile: Ember.computed('file', {
      get(key) {
        let _this = this;

        let value = null;
        this.file.then(file => {
          _this.set('resolvedFile', file);

          value = file;
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    solved: Ember.computed.equal('state.id', 'SYS_07'),
    closed: Ember.computed('state.id', {
      get(key) {
        let value = false;

        let _this = this;

        let states = ['SYS_10'];
        this.state.then(state => {
          if (state) {
            _this.set('closed', states.contains(state.get('id')));
          }
        });
        return value;
      },

      set(key, value) {
        return value;
      }

    }),
    unopened: Ember.computed.or('solved', 'closed'),
    unsavedDependencies: Ember.computed.filterBy('dependencies', 'id', null),
    hasUnsavedDependencies: Ember.computed.gt('unsavedDependencies.length', 0),
    allGradeCosts: Ember.computed.mapBy('resolvedWorkLogs', 'actualGradeCost'),
    normalSubTickets: Ember.computed.filterBy('children', 'checklist', 0),
    checklistSubTickets: Ember.computed.filterBy('children', 'checklist', 1),
    childrenMapByExpenses: Ember.computed.mapBy('children', 'plannedExpensesInHour'),
    subTicketsAllExpenses: Ember.computed.sum('childrenMapByExpenses'),
    addTicketUserAssoc: function () {
      let _this = this;

      this.store.createRecord('ticketUserAssoc', {
        ticket: _this
      });
    },
    reactionTimeDeadline: Ember.computed('createDate', 'serviceLevel.reactionTime', function () {
      let cd = (0, _moment.default)(this.createDate);
      return cd.add(this.get('serviceLevel.reactionTime'), 'm');
    }),
    startTimeDeadline: Ember.computed('createDate', 'serviceLevel.startTime', function () {
      let cd = (0, _moment.default)(this.createDate);
      return cd.add(this.get('serviceLevel.startTime'), 'm');
    }),
    solvingTimeDeadline: Ember.computed('createDate', 'serviceLevel.solvingTime', function () {
      let cd = (0, _moment.default)(this.createDate);
      return cd.add(this.get('serviceLevel.solvingTime'), 'm');
    }),
    filteredServiceLevels: Ember.computed('operationServiceLevel.serviceLevels.[]', 'type.id', function () {
      const typeId = this.get('type.id');
      const serviceLevels = this.get('operationServiceLevel.serviceLevels');

      if (!serviceLevels) {
        return Ember.A();
      }

      return serviceLevels.filter(sl => {
        return sl.get('type.id') === typeId;
      });
    }),
    priorities: Ember.computed.mapBy('filteredServiceLevels', 'priority'),
    sort: ['value'],
    sortedPriorites: Ember.computed.sort('priorities', 'sort'),
    uniqueSortedPriorites: Ember.computed.uniqBy('state'),
    preTicket: Ember.computed('serviceLevel.repairService', function () {
      return this.state.get('id') === 'SYS_17' || this.state.get('id') === 'SYS_19';
    }),
    hasServiceLevelId: Ember.computed('serviceLevel.id', function () {
      return !!this.belongsTo('serviceLevel').id();
    }),
    isRepairTicket: Ember.computed('serviceLevel.repairService', function () {
      return !!this.get('serviceLevel.repairService');
    }),
    notCompletedDependencies: Ember.computed('dependencies.@each.ticketStateId', function () {
      const dependencies = this.get('dependencies');

      if (!dependencies) {
        return Ember.A();
      }

      return dependencies.filter(dep => dep.get('ticketStateId') !== 'SYS_07');
    }),
    hasNotCompletedDependency: Ember.computed('notCompletedDependencies.[]', function () {
      return this.get('notCompletedDependencies').length > 0;
    }),

    async setStartEndDates() {
      let forceUpdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const tickets = await this.get('productResult.tickets'); // what if this ticket has no productResult?

      if (!tickets) {
        return;
      }

      let childsStart = tickets.mapBy('plannedStartDate');

      let minStart = _underscore.default.min(childsStart);

      let childsEnd = tickets.mapBy('plannedEndDate');

      let maxEnd = _underscore.default.max(childsEnd);

      if (typeof minStart === 'number') {
        let newdate = new Date(minStart);
        newdate.setUTCHours(0, 0, 0, 0);
        minStart = newdate;
      }

      if (typeof maxEnd === 'number') {
        let newdate = new Date(maxEnd);
        newdate.setUTCHours(0, 0, 0, 0);
        maxEnd = newdate;
      }

      let productResult = await this.get('productResult');

      if (isValidDate(maxEnd)) {
        productResult.set('plannedStartDate', minStart);
      }

      if (isValidDate(maxEnd)) {
        productResult.set('plannedEndDate', maxEnd);
      }

      let productResults = await this.get('productResult.file.productResults');
      productResults = productResults.filter(pr => pr.billable);
      const childsStartPR = productResults.mapBy('plannedStartDate');
      const childsEndPR = productResults.mapBy('plannedEndDate');
      const minStartPR = Ember.isEmpty(childsStartPR) ? null : _underscore.default.min(childsStartPR);
      const maxEndPR = Ember.isEmpty(childsEndPR) ? null : _underscore.default.max(childsEndPR);
      let isOpportunity = await productResult.get('file.isOpportunity');

      if (forceUpdate || !isOpportunity) {
        if (minStartPR) {
          this.get('productResult.file').set('dateStart', minStartPR);
        }

        if (maxEndPR) {
          this.get('productResult.file').set('dateEnd', maxEndPR);
        }
      }
    }

  });

  _exports.default = _default;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
});