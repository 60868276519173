define("iris/models/invoice", ["exports", "ember-data-copyable", "ember-data/model", "ember-data/attr", "ember-data/relationships", "bankers-rounding", "moment", "ember-cp-validations"], function (_exports, _emberDataCopyable, _model, _attr, _relationships, _bankersRounding, _moment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    subject: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateFulfilled: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateIssued: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateEntered: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    datePaymentDue: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateSettlement: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    dateExchangeRate: (0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['currency'],
      disabled: Ember.computed(function () {
        return this.model.get('currency.id') === 'SYS_01';
      }).volatile(),
      presence: true
    }),
    currency: [(0, _emberCpValidations.validator)('presence', true)],
    paymentMethod: [(0, _emberCpValidations.validator)('presence', true)],
    partner: [(0, _emberCpValidations.validator)('presence', true)],
    project: [(0, _emberCpValidations.validator)('presence', true)],
    profitCenter: [(0, _emberCpValidations.validator)('presence', true)],
    fileBusinessType: [(0, _emberCpValidations.validator)('presence', true)],
    fileType: [(0, _emberCpValidations.validator)('presence', true)],
    navConnection: [(0, _emberCpValidations.validator)('presence', true)],
    invoiceOwner: [(0, _emberCpValidations.validator)('presence', true)],
    bankCode: [(0, _emberCpValidations.validator)('presence', true)]
  });
  let now = new Date();

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    accountNumber: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    dateFulfilled: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateIssued: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateEntered: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    datePaymentDue: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    datePaid: (0, _attr.default)('date'),
    dateSettlement: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    netto: (0, _attr.default)('number', {
      fromWidget: 'mask'
    }),
    vat: (0, _attr.default)('number'),
    invoiceNumber: (0, _attr.default)('number'),
    comment: (0, _attr.default)('string'),
    longComment: (0, _attr.default)('string'),
    paidBalance: (0, _attr.default)('number'),
    printed: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    currencyRate: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    currencyRound: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    preInvoice: (0, _attr.default)('boolean'),
    templateId: (0, _attr.default)('string', {
      defaultValue: '000'
    }),
    transactionId: (0, _attr.default)('string'),
    navStatus: (0, _attr.default)('string'),
    dateExchangeRate: (0, _attr.default)('date', {
      defaultValue() {
        return now;
      }

    }),
    // technical attributes for Auto::Grid
    brutto: (0, _attr.default)(),
    info: (0, _attr.default)(),
    balance: (0, _attr.default)(),
    currency: (0, _relationships.belongsTo)('currency'),
    paymentMethod: (0, _relationships.belongsTo)('paymentMethod'),
    partner: (0, _relationships.belongsTo)('partner'),
    invoiceType: (0, _relationships.belongsTo)('invoiceType'),
    project: (0, _relationships.belongsTo)('project'),
    parent: (0, _relationships.belongsTo)('invoice'),
    bankCode: (0, _relationships.belongsTo)('partnerBankcode'),
    profitCenter: (0, _relationships.belongsTo)('profitCenter'),
    fileBusinessType: (0, _relationships.belongsTo)('fileBusinessType'),
    fileType: (0, _relationships.belongsTo)('fileType'),
    navConnection: (0, _relationships.belongsTo)('navSetting'),
    invoiceOwner: (0, _relationships.belongsTo)('partner'),
    createdBy: (0, _relationships.belongsTo)('account'),
    sentBy: (0, _relationships.belongsTo)('account'),
    proformaInvoice: (0, _relationships.belongsTo)('invoice', {
      inverse: null
    }),
    items: (0, _relationships.hasMany)('invoiceItem'),
    payments: (0, _relationships.hasMany)('invoicePayment'),
    invoiceItemAllocations: (0, _relationships.hasMany)('invoiceItemAllocation'),
    roundingDigits: Ember.computed.alias('currency.round'),
    isPaid: Ember.computed.bool('datePaid'),
    currentYear: Ember.computed('dateIssued', function () {
      let issued = (0, _moment.default)(this.get('dateIssued'));
      return (0, _moment.default)().isSame(issued, 'year');
    }),
    defaultInPaying: Ember.computed('datePaymentDue', 'datePaid', function () {
      let datePaymentDue = (0, _moment.default)(this.get('datePaymentDue'));
      let datePaid = (0, _moment.default)(this.get('datePaid'));
      return datePaid.diff(datePaymentDue, 'd');
    }),
    reverseInvoice: Ember.computed.equal('invoiceType.id', 'SYS_02'),
    // burnt in
    normalInvoice: Ember.computed.equal('invoiceType.id', 'SYS_01'),
    correctingInvoice: Ember.computed.equal('invoiceType.id', 'SYS_03'),
    gross: Ember.computed('nett', 'vat', function () {
      return this.get('netto') + this.get('vat');
    }),
    sumNet: Ember.computed('items.[]', 'items.@each.quantity', 'items.@each.price', 'roundingDigits', function () {
      return this.get('items').reduce((prev, item) => {
        return prev + (0, _bankersRounding.default)(item.get('quantity') * item.get('price'), this.roundingDigits);
      }, 0);
    }),
    sumGross: Ember.computed('items.[]', 'items.@each.quantity', 'items.@each.price', 'items.@each.vat', 'roundingDigits', function () {
      return this.get('items').reduce((prev, item) => {
        const nettoPrice = (0, _bankersRounding.default)(item.get('quantity') * item.get('price'), this.roundingDigits);
        return prev + (0, _bankersRounding.default)(nettoPrice * (1 + item.get('vat')), this.roundingDigits);
      }, 0);
    }),
    hasTransactionId: Ember.computed('transactionId', function () {
      const transactionId = this.get('transactionId');
      return transactionId && transactionId !== 'Error';
    }),
    hasEndStatus: Ember.computed('navStatus', function () {
      const navStatus = this.get('navStatus');
      return ['DONE', 'ABORTED', 'WARN'].includes(navStatus);
    }),
    needStatusCheck: Ember.computed('hasTransactionId', 'hasEndStatus', function () {
      return this.get('hasTransactionId') && !this.get('hasEndStatus');
    }),
    alreadyPaid: Ember.computed('gross', 'paidBalance', function () {
      return this.get('paidBalance') >= this.get('gross');
    }),
    paidDays: Ember.computed('alreadyPaid', 'datePaymentDue', 'datePaid', function () {
      const alreadyPaid = this.get('alreadyPaid');
      const datePaymentDue = this.get('datePaymentDue');
      let datePaid = alreadyPaid ? (0, _moment.default)(this.get('datePaid')) : (0, _moment.default)();
      return (0, _moment.default)(datePaymentDue).diff(datePaid, 'd');
    }),
    keepTrackPayments: Ember.computed.reads('accountNumber'),
    isAdvanceInvoice: Ember.computed.equal('invoiceType.id', 'SYS_05'),
    isFinalInvoice: Ember.computed.equal('invoiceType.id', 'SYS_06'),
    isProforma: Ember.computed.equal('invoiceType.id', 'SYS_09'),

    async makeJSON() {
      let record = this.toJSON();
      record.dateIssued = null;
      const project = await this.get('project');
      let partner = await this.get('partner');
      let addresses = await partner.get('addresses');
      let currency = await this.get('currency');
      let parent = await this.get('parent');
      let invoiceType = await this.get('invoiceType');
      let items = await this.get('items');
      let paymentMethod = await this.get('paymentMethod');
      let bankCode = await this.get('bankCode');
      let invoiceOwner = await this.get('invoiceOwner');
      addresses = JSON.parse(JSON.stringify(addresses.toArray()));

      for (let address of addresses) {
        if (address.country) {
          let res = await this.get('store').findRecord('country', address.country);
          address.country = res.toJSON();
        }
      }

      record.items = JSON.parse(JSON.stringify(items.toArray()));

      for (const item of record.items) {
        if (item.vatRef) {
          const vat = await this.get('store').findRecord('vat', item.vatRef);
          item.vatRef = vat.toJSON();
        }
      }

      record.bankCode = bankCode.toJSON();
      record.currency = currency.toJSON();
      record.paymentMethod = paymentMethod.toJSON();
      record.partner = partner.toJSON();

      if (parent) {
        record.parent = parent.toJSON();
      }

      record.project = project.toJSON();
      record.invoiceType = invoiceType.toJSON();
      record.invoiceType.id = invoiceType.id;
      record.partner.addresses = JSON.parse(JSON.stringify(addresses.toArray()));

      for (const address of record.partner.addresses) {
        address.addressTypeId = address.addressType;
      }

      record.invoiceOwner = invoiceOwner.toJSON();
      record.invoiceOwner.id = invoiceOwner.id;
      return record;
    }

  });

  _exports.default = _default;
});