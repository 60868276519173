define("iris/components/modals/completion-certificate/component", ["exports", "iris/utils/converter", "ember-concurrency"], function (_exports, _converter, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompletionCertifiacate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('sessionAccount.account.language'), _dec7 = Ember.computed.alias('data.model'), _dec8 = Ember._action, (_class = class CompletionCertifiacate extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "alreadyRead", false);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);

      _initializerDefineProperty(this, "loading", _descriptor4, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor5, this);

      _initializerDefineProperty(this, "currentLanguage", _descriptor6, this);

      _initializerDefineProperty(this, "model", _descriptor7, this);
    }

    *generateCompletionCertificatePDFTask(activeProposal) {
      const flashMessages = this.get('flashMessages');
      const languageCode = this.get('currentLanguage.languageCode');
      let invoice; // Make invoice
      // Since NAV endpoint is not exposed for PMs, the invoice created on the backend

      try {
        // check if already exists preInvoice for this proposal
        if (activeProposal.preInvoice) {
          let confirm = yield this.modals.open('beta/modals/confirmation', {
            title: this.intl.t('general.warning'),
            body: this.intl.t('projects.completion-certificate-warning')
          });

          if (!confirm) {
            throw new Error('err');
          } else {
            invoice = yield this.ajax.call('POST', 'file-outgoing-proposal', '', 'create-invoice', {
              data: activeProposal
            }); // Generate Completion Certificate

            const result = yield this.get('ajax').call('POST', 'file-outgoing-proposal', '', 'pdf', {
              data: activeProposal,
              languageCode
            });
            yield this.loading.run(async () => {
              saveAs(_converter.default.b64toBlob(result.$data, 'application/pdf'), `TIG${moment().format('YYYY/MM/DD')}.pdf`);
            });
          }
        } else {
          invoice = yield this.ajax.call('POST', 'file-outgoing-proposal', '', 'create-invoice', {
            data: activeProposal
          }); // Generate Completion Certificate

          const result = yield this.get('ajax').call('POST', 'file-outgoing-proposal', '', 'pdf', {
            data: activeProposal,
            languageCode
          });
          yield this.loading.run(async () => {
            saveAs(_converter.default.b64toBlob(result.$data, 'application/pdf'), `TIG${moment().format('YYYY/MM/DD')}.pdf`);
          });
        }

        flashMessages.success(this.intl.t('preInvoiceGenerationSuccess', {
          subject: invoice.subject,
          id: invoice.id
        }), {
          title: this.intl.t('invoice.preInvoice'),
          icon: 'check',
          sticky: true
        });
      } catch (e) {
        if (e.errors && e.errors.length) {
          e.errors.forEach(err => {
            let text = err.detail || err.title;
            flashMessages.danger(text, {
              title: this.intl.t('general.generation.error'),
              icon: 'times',
              sticky: true
            });
          });
        } else {
          console.error(e);
        }
      }

      this.close(invoice);
    }

    generateCompletionCertificatePDF(activeProposal) {
      this.generateCompletionCertificatePDFTask.perform(activeProposal);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentLanguage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generateCompletionCertificatePDFTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "generateCompletionCertificatePDFTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateCompletionCertificatePDF", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "generateCompletionCertificatePDF"), _class.prototype)), _class));
  _exports.default = CompletionCertifiacate;
});