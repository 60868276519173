define("iris/components/forms/entity-form-software", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    mode: 'entity',
    init: function () {
      this._super();

      this.set('model.isSatellite', 1);
    },
    tabs: Ember.computed('mode', function () {
      let mode = this.get('mode');

      if (mode === 'popup') {
        return [];
      }

      let category = this.get('category');
      let monitoring = this.get('model.monitoringStartDate');
      let res = [];

      if (category === 'software') {
        res = [{
          name: this.intl.t('entity-type.entityAttributes'),
          route: 'cmdb.entity-types.entities.' + mode + '.attributes'
        }, {
          name: this.intl.t('entity-type.relations'),
          route: 'cmdb.entity-types.entities.' + mode + '.relations'
        }, {
          name: this.intl.t('entity-type.software'),
          route: 'cmdb.entity-types.entities.' + mode + '.software'
        }, {
          name: this.intl.t('entity-type.otherData'),
          route: 'cmdb.entity-types.entities.' + mode + '.overview'
        }, {
          name: this.intl.t('entity-type.contacts'),
          route: 'cmdb.entity-types.entities.' + mode + '.contacts'
        }];

        if (mode === 'entity') {
          res.push({
            name: this.intl.t('entity-type.moves'),
            route: 'cmdb.entity-types.entities.' + mode + '.moves'
          });
          res.push({
            name: this.intl.t('forms.wiki-knowledge-base'),
            route: 'cmdb.entity-types.entities.' + mode + '.xwiki'
          });
          res.push({
            name: this.intl.t('projects.files.tabs.attachments'),
            route: 'cmdb.entity-types.entities.' + mode + '.documents'
          });
        }
      } else if (category === 'license') {
        res = [{
          name: this.intl.t('entity-type.entityAttributes'),
          route: 'cmdb.entity-types.entities.' + mode + '.attributes'
        }];

        if (mode === 'entity') {
          res.push({
            name: this.intl.t('entity-type.licensedEntity'),
            route: 'cmdb.entity-types.entities.' + mode + '.licensed-entity'
          });
          res.push({
            name: this.intl.t('projects.files.tabs.attachments'),
            route: 'cmdb.entity-types.entities.' + mode + '.documents'
          });
        }
      } else {
        res = [{
          name: this.intl.t('entity-type.entityAttributes'),
          route: 'cmdb.entity-types.entities.' + mode + '.attributes'
        }, {
          name: this.intl.t('entity-type.relations'),
          route: 'cmdb.entity-types.entities.' + mode + '.relations'
        }];

        if (monitoring) {
          res.push({
            name: this.intl.t('entity-procedure.modelName'),
            route: 'cmdb.entity-types.entities.' + mode + '.procedures'
          });
        }
      }

      return res;
    }),
    siteZoneOptions: Ember.computed('siteZones.[]', 'model.partner.id', 'sessionAccount.region', function () {
      let region = this.get('sessionAccount.region');
      let result = Ember.A();
      region.get('ownerPartner').then(ownerPartner => {
        if (ownerPartner.id != this.get('model.partner.id')) {
          ownerPartner.get('siteZones').then(siteZones => {
            siteZones.forEach(siteZone => {
              if (siteZone.get('default')) {
                result.pushObject(siteZone);
              }
            });
          });
        }
      });

      if (this.get('siteZones')) {
        let partnerZones = this.get('siteZones').filter(siteZone => {
          return siteZone.get('partner.id') === this.get('model.partner.id');
        });
        result.pushObjects(partnerZones);
      }

      return result;
    }),
    availableQtyObserver: Ember.observer('model.qty', 'model.licensedEntities.[]', function () {
      const model = this.get('model');
      const licensedEntities = this.get('model.licensedEntities');
      let used = licensedEntities.length;
      model.set('availableQty', model.get('qty') - used);
    }).on('init'),
    contacts: Ember.computed('model.partner.id', function () {
      if (!this.get('model.partner.id')) return Ember.A();
      return this.get('store').query('contact', {
        filter: {
          where: {
            partnerId: this.get('model.partner.id')
          }
        }
      });
    }),
    types: Ember.computed('entityTypes', function () {
      let entityTypes = this.get('entityTypes');

      if (entityTypes) {
        let entityTypeOptions = Ember.A();
        let normalEntityType = entityTypes.filter(et => {
          return et.id !== null;
        });
        let hardware = normalEntityType.filter(et => {
          return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000001';
        });
        let software = normalEntityType.filter(et => {
          return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000002';
        });
        entityTypeOptions.pushObject({
          groupName: this.intl.t('cmdb.hardware'),
          options: hardware
        });
        entityTypeOptions.pushObject({
          groupName: this.intl.t('cmdb.software'),
          options: software
        });
        return entityTypeOptions;
      } else {
        return Ember.A();
      }
    }),
    actions: {
      async beforeSave(entity, _, cb) {
        const mismatchPartners = await this.api.memberAction('entity', 'check-mismatch-partner', entity.id, {
          method: 'GET'
        });

        if (Object.keys(mismatchPartners).length === 0) {
          return cb();
        }

        const partnerId = entity.belongsTo('partner').id();
        const result = await this.modals.open('modals/cmdb-mismatch-partner', {
          mismatchPartners,
          partnerId
        }, {
          className: _modals.ModalLayoutClassName.L
        });

        if (result === false) {
          return;
        }

        cb();
      },

      changeParent: function (selected) {
        let store = this.get('store');
        let model = this.get('model');
        model.get('slaveRelations').then(slaveRelations => {
          slaveRelations.invoke('deleteRecord', true);
          store.findRecord('entityRelationType', 'SYS_04').then(runType => {
            let record = store.createRecord('entityRelation', {
              entityRelationType: runType,
              masterEntity: selected,
              slaveEntity: model
            });
            slaveRelations.pushObject(record);
          });
        });
        this.set('model.parent', selected);
        this.set('model.partner', selected.get('partner'));
      },
      changePartner: function (selected) {
        this.set('model.contact', null);
        this.set('model.partner', selected);
        let setDefault = false;
        selected.get('siteZones').then(siteZones => {
          siteZones.forEach(siteZone => {
            if (siteZone.get('default')) {
              this.set('model.siteZone', siteZone);
              setDefault = true;
            }
          });

          if (!setDefault) {
            let region = this.get('sessionAccount.region');
            region.get('ownerPartner').then(ownerPartner => {
              ownerPartner.get('siteZones').then(siteZones => {
                siteZones.forEach(siteZone => {
                  if (siteZone.get('default')) {
                    this.set('model.siteZone', siteZone);
                  }
                });
              });
            });
          }
        });
      },

      toggleNameField() {
        if (!this.get('model.generateName')) {
          this.set('model.name', '');
        } else {
          this.set('model.name', '-');
        }
      },

      toggleSwitch: function () {
        if (this.get('model.monitoring')) {
          this.set('model.monitoring', 0);
        } else {
          this.set('model.monitoring', 1);
        }
      },
      stopMonitoring: function (entity) {
        const flashMessages = Ember.get(this, 'flashMessages');
        entity.save(true).then(savedEntity => {
          this.get('ajax').call('POST', 'entity', savedEntity.get('id'), 'stop-monitoring').then(res => {
            entity.set('monitoringStartDate', null);
            this.get('router').transitionTo('cmdb.entity-types.entities.entity.attributes', savedEntity.get('entityType.id'), savedEntity.get('id'));
          }).catch(err => {
            console.error(err);
            let errorMessages = '';

            if (err.errors) {
              err.errors.forEach(error => {
                errorMessages += error.detail + '.';
              });
            } else {
              errorMessages += err;
            }

            flashMessages.danger(errorMessages, {
              title: 'Stop monitoring',
              icon: 'warning',
              sticky: true
            });
          });
        });
      }
    },
    partnerHash: Ember.computed('model', function () {
      return {
        and: [{
          deletedAt: null
        }, {
          isActive: 1
        }]
      };
    })
  });

  _exports.default = _default;
});