define("iris/models/ticket-state", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "iris/utils/enum"], function (_exports, _model, _attr, _emberCpValidations, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TICKET_STATE_NAME = _exports.TICKET_STATE_ID = void 0;
  // import { belongsTo, hasMany } from 'ember-data/relationships';
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    project: (0, _attr.default)('boolean', {
      defaultValue: 0
    }),
    sd: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    management: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    applicationToIntervene: (0, _attr.default)('boolean', {
      defaultValue: 0
    }),
    isSystemType: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
  const TICKET_STATE_ID = (0, _enum.makeEnum)([{
    Default: '000'
  }, {
    Unresolved: 'SYS_02'
  }, {
    WaitingForRelease: 'SYS_03'
  }, {
    InProgress: 'SYS_04'
  }, {
    WaitingForReporter: 'SYS_05'
  }, {
    WaitingForThirdParty: 'SYS_06'
  }, {
    Resolved: 'SYS_07'
  }, {
    ImmediatelyResolved: 'SYS_08'
  }, {
    Rejected: 'SYS_09'
  }, {
    Closed: 'SYS_10'
  }, {
    New: 'SYS_11'
  }, {
    Planned: 'SYS_12'
  }, {
    Approved: 'SYS_13'
  }, {
    Done: 'SYS_15'
  }, {
    Discarded: 'SYS_15'
  }, {
    Suspended: 'SYS_16'
  }, {
    PreReport: 'SYS_17'
  }, {
    WaitingForDeadline: 'SYS_18'
  }, {
    Draft: 'SYS_19'
  }]);
  _exports.TICKET_STATE_ID = TICKET_STATE_ID;
  const TICKET_STATE_NAME = (0, _enum.makeEnum)([{
    Default: 'Default'
  }, {
    Unresolved: 'Unresolved'
  }, {
    WaitingForRelease: 'WaitingForRelease'
  }, {
    InProgress: 'InProgress'
  }, {
    WaitingForReporter: 'WaitingForReporter'
  }, {
    WaitingForThirdParty: 'WaitingForThirdParty'
  }, {
    Resolved: 'Resolved'
  }, {
    ImmediatelyResolved: 'ImmediatelyResolved'
  }, {
    Rejected: 'Rejected'
  }, {
    Closed: 'Closed'
  }, {
    New: 'New'
  }, {
    Planned: 'Planned'
  }, {
    Approved: 'Approved'
  }, {
    Done: 'Done'
  }, {
    Discarded: 'Discarded'
  }, {
    Suspended: 'Suspended'
  }, {
    PreReport: 'PreReport'
  }, {
    WaitingForDeadline: 'WaitingForDeadline'
  }, {
    Draft: 'Draft'
  }]);
  _exports.TICKET_STATE_NAME = TICKET_STATE_NAME;
});