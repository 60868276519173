define("iris/components/beta/grid/component", ["exports", "iris/lib/filters", "iris/lib/filters/types"], function (_exports, _filters, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The grid component is displaying data rows based on column information. It
   * does NOT fetch data, rows have to be passed to a grid explicitly. It can,
   * however, instruct components above to change the order (property +
   * direction), page & limit properties and manage row selection. Also, it can
   * display data fetching status, like loading & error - these statuses have to
   * be passed to the component as well. As a future development, passing an EC
   * task to the grid could be the source of these 2 statues.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    storage: null,
    // @, Storage
    columns: null,
    // @, []
    rows: null,
    // @, []
    rowActions: null,
    // @, []
    fuzzySearchTerm: null,
    // @
    bulkActions: null,
    // @, CP => []
    params: Ember.computed.alias('storage.params'),
    tableColumns: Ember.computed('columns.[]', function () {
      return (0, _filters.filterByVisiblity)(this.columns, _types.AttributeVisibility.Table);
    }),
    sorts: Ember.computed('tableColumns.[]', 'params.order.{direction,property}', function () {
      let {
        property,
        direction
      } = this.params.order;
      let column = this.tableColumns.find(c => c.orderProperty === property);

      if (!property || !column) {
        return [];
      }

      return [{
        valuePath: column.valuePath,
        isAscending: direction === _types.Direction.Asc
      }];
    }),

    init() {
      this._super(...arguments);

      if (this.bulkActions) {
        // eslint-disable-next-line ember/no-observers
        this.addObserver('rows.[]', this, function () {
          const {
            selection
          } = this.bulkActions;

          if (selection) {
            const existingSelectedRows = this.rows.filter(r => selection.some(s => s === r || s.id === r.id));
            const otherSelectedRows = selection.filter(s => !this.rows.some(r => r === s || r.id === s.id));
            this.set('bulkActions.selection', [...existingSelectedRows, ...otherSelectedRows]);
          }
        });
      }
    },

    actions: {
      onUpdateSorts(sorts) {
        if (sorts.length > 1) {
          // No multi-property sort yet, so just return because the table is
          // already sorted by the first property.
          return;
        }

        let [sort] = sorts;

        if (!sort) {
          this.storage.reset(['order', 'direction']);
        } else {
          let column = this.tableColumns.find(c => c.valuePath === sort.valuePath);
          this.storage.update('order', {
            property: column.orderProperty,
            direction: sort.isAscending ? _types.Direction.Asc : _types.Direction.Desc
          });
        } // Always reset the current page, because when the order is changed, we
        // don't want to start from page 1, not the Nth page...


        this.storage.reset(['page']);
      },

      selectRow(selection) {
        this.set('bulkActions.selection', selection);

        if (this.onSelectRows) {
          this.onSelectRows(selection);
        }
      }

    }
  });

  _exports.default = _default;
});