define("iris/helpers/change-request-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      let text = '',
          color = '';

      switch (params[0]) {
        case 1:
          text = this.intl.t('operation.change-request.fromCustomer');
          color = 'success';
          break;

        case 2:
          text = this.intl.t('operation.change-request.fromProvider');
          color = 'info';
          break;

        case 3:
          text = this.intl.t('operation.change-request.fromThirdParty');
          color = 'warning';
          break;
      }

      const fullText = `<span class="badge badge-${color}">${text}</span>`;
      return Ember.String.htmlSafe(fullText);
    }

  });

  _exports.default = _default;
});