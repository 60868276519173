define("iris/routes/finances/invoices/new", ["exports", "iris/utils/model", "bankers-rounding", "iris/utils/create-record", "iris/mixins/protected-route"], function (_exports, _model, _bankersRounding, _createRecord, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),

    createInvoice() {
      return (0, _createRecord.createInvoice)(this.store, {
        region: this.sessionAccount.region
      });
    },

    async model(params) {
      if (params) {
        this.set('params', params);
      }

      let store = this.store;

      let _this = this;

      return Ember.RSVP.hash({
        invoice: await _this.createInvoice(params),
        vats: store.findAll('vat'),
        uoms: store.findAll('uom')
      });
    },

    setupController(controller, model) {
      model.invoice.startTrack();

      this._super(controller, model);

      const mode = this.get('mode');
      const title = mode === 'edit' ? this.intl.t('invoice.editPreInvoice', {
        id: model.invoice.id
      }) : this.intl.t('finances.main.new-invoice');
      controller.set('mode', mode);
      controller.set('title', title);
    },

    async summarize(record) {
      let {
        model,
        validations
      } = await (0, _model.validateRecord)(record);
      model.setValidated();

      if (!validations.get('isValid')) {
        return {};
      }

      let items = await record.get('items');
      let allValid = true;
      let allNet = 0,
          allVat = 0;
      const roundingDigits = record.get('currency.round');

      for (let item of items.toArray()) {
        let {
          model,
          validations
        } = await (0, _model.validateRecord)(item);

        if (!validations.get('isValid')) {
          model.setValidated();
          allValid = false;
        } else {
          const quantity = item.get('quantity');
          const netto = (0, _bankersRounding.default)(item.get('price') * quantity, roundingDigits);
          const vatValue = (0, _bankersRounding.default)(netto * item.get('vat'), roundingDigits);
          item.set('netto', netto);
          item.set('vatValue', vatValue);
          item.set('brutto', (0, _bankersRounding.default)(vatValue + netto, roundingDigits));
          allNet += netto;
          allVat += vatValue;
        }
      }

      return {
        allValid: allValid,
        allNet: (0, _bankersRounding.default)(allNet, roundingDigits),
        allVat: (0, _bankersRounding.default)(allVat, roundingDigits)
      };
    },

    actions: {
      async addItem() {
        let items = await this.controller.get('model.invoice.items');
        items.pushObject(this.store.createRecord('invoiceItem'));
      },

      async removeItem(item) {
        let items = await this.controller.get('model.invoice.items');
        items.removeObject(item);
        item.destroyRecord();
      },

      saveAsPreInvoice(record, cb) {
        record.set('preInvoice', true);
        this.send('validateInvoiceItems', record, cb);
      },

      saveAsNormalInvoice(record, cb) {
        record.set('preInvoice', false);
        this.send('validateInvoiceItems', record, cb);
      },

      async validateInvoiceItems(record, cb) {
        let {
          allValid,
          allNet,
          allVat
        } = await this.summarize(record);
        let {
          controller
        } = this;

        if (allValid) {
          record.set('netto', allNet);
          record.set('vat', allVat); //cb(record)

          this.set('callback', cb);
          this.set('record', record);
          controller.set('removeConfirmationButtons', false);
          let json = await record.makeJSON();
          controller.set('src', '');
          json.regionId = this.get('sessionAccount.account.regionId');

          try {
            let response = await this.get('ajax').call('POST', 'invoice', '', 'preview', {
              inputs: json
            });
            controller.set('src', response.$data);
            controller.set('status', null);
            this.controller.set('confirmation', true);
          } catch (e) {
            const flashMessages = Ember.get(this, 'flashMessages');
            e.errors.forEach(err => {
              let text = err.detail || err.title;

              if (text.includes("Cannot read property 'country' of undefined")) {
                text = this.intl.t('finances.main.partner-address-error');
              }

              flashMessages.danger(text, {
                title: this.intl.t('finances.main.create-invoice-error'),
                icon: 'times',
                sticky: true
              });
            });
          }
        }
      },

      async preview(invoice) {
        const {
          controller
        } = this; // only valid invoice can be previewed

        let {
          model,
          validations
        } = await (0, _model.validateRecord)(invoice);

        if (!validations.get('isValid')) {
          model.setValidated();
          return false;
        }

        let {
          allValid
        } = await this.summarize(invoice);

        if (!allValid) {
          return false;
        }

        let json = await invoice.makeJSON();
        json.regionId = this.get('sessionAccount.account.regionId');
        controller.set('removeConfirmationButtons', true);
        controller.set('confirmation', true);
        controller.set('preview', true);
        controller.set('status', this.intl.t('general.pdf-generate'));

        try {
          let response = await this.get('ajax').call('POST', 'invoice', '', 'preview', {
            inputs: json
          });
          controller.set('status', null);
          controller.set('src', response.$data);
          controller.set('invoiceDone', true);
        } catch (e) {
          const flashMessages = Ember.get(this, 'flashMessages');
          e.errors.forEach(err => {
            let text = err.detail || err.title;

            if (text.includes("Cannot read property 'country' of undefined")) {
              text = this.intl.t('finances.main.partner-address-error');
            }

            flashMessages.danger(text, {
              title: this.intl.t('finances.main.create-invoice-error'),
              icon: 'times',
              sticky: true
            });
          });
          controller.set('invoiceDone', true);
          return false;
        }
      },

      async afterSave(savedInvoice) {
        let items = await savedInvoice.get('items');
        const controller = this.controller;
        let parentController = this.controllerFor('finances.invoices');
        controller.set('status', this.intl.t('invoice.saveItems'));
        let promises = items.invoke('save');
        Ember.RSVP.all(promises).then(items => {
          controller.set('status', this.intl.t('general.pdf-generate'));
          this.get('ajax').call('POST', 'invoice', '', 'pdf', {
            id: savedInvoice.id
          }).then(response => {
            controller.set('src', response.$data);

            if (savedInvoice.get('preInvoice') === false && savedInvoice.get('isProforma') === false) {
              controller.set('status', this.intl.t('invoice.sendingToNAV'));
              this.get('ajax').call('POST', 'invoice', '', 'send-invoice', {
                id: savedInvoice.id
              }).then(response => {
                controller.set('processingIndicator', false);
                controller.set('status', this.intl.t('invoice.sendToNAVSuccess', {
                  response
                }));
                controller.set('invoiceDone', true);
                parentController.storage.refresh();
                Ember.run.next(function () {
                  parentController.storage.refresh();
                });
              }).catch(e => {
                const flashMessages = Ember.get(this, 'flashMessages');
                e.errors.forEach(err => {
                  let text = err.detail || err.title;
                  flashMessages.danger(text, {
                    title: this.intl.t('invoice.sendToNAVError'),
                    icon: 'times',
                    sticky: true
                  });
                });
                controller.set('status', null);
                controller.set('invoiceDone', true);
                parentController.storage.refresh();
                Ember.run.next(function () {
                  parentController.storage.refresh();
                });
              });
            } else {
              controller.set('status', null);
              controller.set('invoiceDone', true);
              parentController.storage.refresh();
              Ember.run.next(function () {
                parentController.storage.refresh();
              });
            }
          }).catch(e => {
            const flashMessages = Ember.get(this, 'flashMessages');
            e.errors.forEach(err => {
              let text = err.detail || err.title;
              flashMessages.danger(text, {
                title: this.intl.t('general.pdf-generate-error'),
                icon: 'times',
                sticky: true
              });
            });
          });
        }).catch(e => {
          // if any of the promises fails.
          const flashMessages = Ember.get(this, 'flashMessages');
          e.errors.forEach(err => {
            let text = err.detail || err.title;
            flashMessages.danger(text, {
              title: this.intl.t('invoice.saveItemsError'),
              icon: 'times',
              sticky: true
            });
          });
          return false;
        });
      },

      confirmInvoice() {
        const invoice = this.get('record');
        const id = invoice.belongsTo('navConnection').id();

        if (!id) {
          this.flashMessages.danger(this.intl.t('finances.nav-instruction'), {
            title: this.intl.t('general.error'),
            icon: 'times',
            sticky: true
          });
          return;
        }

        let cb = this.get('callback');
        cb(invoice);
        this.controller.set('removeConfirmationButtons', true);
      },

      cancelInvoice() {
        this.controller.set('confirmation', false);
      },

      closeInvoice() {
        this.controller.set('confirmation', false);
        this.controller.set('processingIndicator', true); // invoice is already saved, it is not a preview

        if (!this.controller.get('preview')) {
          this.transitionTo('finances.invoices');
        }

        this.controller.set('src', false);
        this.controller.set('invoiceDone', false);
        this.controller.set('preview', false);
        this.controller.set('removeConfirmationButtons', true);
      }

    }
  });

  _exports.default = _default;
});